import React, { Component } from "react";
import axios from "axios";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Label,
  LabelList,
  Rectangle,
  DefaultTooltipContent,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import "../css/reportcomponew.css";
import WorkInProgress from "./work_in_progress";
import NewFilterCompo from "./NewFilterCompo";
import WithNavigate from "./withNavigate";
import { isMobile, isMobileOnly } from "react-device-detect";
// import { Modal, Button, Tab, Nav } from "react-bootstrap";
import { Modal, Button, Tab, Nav } from "react-bootstrap";
import { error } from "jquery";
import { message } from "antd";

const bar_colors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "red",
  "pink",
  "#8884d8",
];
const pie_colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

// const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;

class ReportComponentNew extends Component {
  state = {
    initialReport: true,
    reportname: "",
    altColor: true,
    loading: false,
    page_error: false,
    error: "",
    loca: this.props.loca,
    reportPName: this.props.reportName,
    bl_report: false,
    isMobile: this.props.isMobile,
    timeline: "",
    x_axis: "",
    y_axis: "",
    reportdata: [],
    reportlist: [],
    reporttype: "",
    col_mn: [],
    column_depend: [],
    filtarray: [],
    filt: {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "", label: "" },
      ch: [],
    },
    timeline: "",
    showFilter: false,
    default_filter: [],
    default_tm: "",
    showModal: false,
    role: "",
    filterString: "",
    showMod: false,
  };

  constructor(props) {
    super(props);
    this.callReport = this.callReport.bind(this);
    this.state.filtarray.push(this.state.filt);
    // this.renderCustomizedLabel = this.renderCustomizedLabel.bind(this);
  }

  componentDidMount() {
    let user_det = JSON.parse(localStorage.getItem("userDetails"));
    this.setState({ role: user_det.role });
    var bl = false;
    const rName =
      this.state.reportPName !== "null" &&
      this.state.reportPName !== "" &&
      this.state.reportPName !== undefined;
    if (rName) {
      this.setState({ bl_report: true });
      bl = true;
    }
    var token = localStorage.getItem("token");
    let rptList = [];
    axios
      .get(this.state.loca + "/loom/get/report/name", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (res) => {
          const rcd = res.data;
          if (rcd !== "") {
            if ("Error" in rcd) {
              this.setState({
                loading: false,
                page_error: true,
                error: rcd.Error,
              });
            } else {
              this.setState({
                reportlist: rcd.reportNameList[2].records,
              });
              rptList = rcd.reportNameList[2].records;
              this.updateSearchParams(rptList);
            }
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
    // if (bl === true) {
    //   this.callReport(this.state.reportPName ,rcd.reportNameList[2].records);
    // }
  }

  updateSearchParams = (rptList) => {
    const newsearchParams = new URLSearchParams(this.props.location?.search);

    let id = newsearchParams.get("id"); //rty
    if (id) {
      this.callReport(id, undefined, rptList);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.isMobile !== state.isMobile) {
      return {
        isMobile: props.isMobile,
      };
    }
    return null;
  }

  filterItem = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  getTableColumns = (tab_name) => {
    if (
      this.state.reportname !== "None" ||
      this.state.reportname !== "Report"
    ) {
      var token = localStorage.getItem("token");
      axios
        .get(this.state.loca + "/loom/get/allcloumns/" + tab_name, {
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then(
          (resp) => {
            let res = resp.data;

            res.columnRecords.unshift({
              id: "0",
              label: "None",
              name: "none",
              userTable: "false",
            });
            this.setState({ col_mn: res.columnRecords });
          },
          (error) => {
            this.props.showErrorCompo();
          }
        );
    }
  };

  callReport(nam, pp, reportList) {
    this.setState({
      page_error: false,
      error: "",
      page_message: false,
      message: "",
    });
    if (nam === "None") {
      // If "None" option is selected, set loading to false and reset other states
      this.setState({
        initialReport: true,
        loading: false,
        reportdata: [],
        reporttype: "",
        reportname: "",
        timeline: "",
        x_axis: "",
        y_axis: "",
        filterString: "",
        filtarray: [],
      });
    } else if (nam === "Report") {
      this.setState({ initialReport: true, reportname: nam });
    } else {
      this.setState({ loading: true });
      var token = localStorage.getItem("token");
      let fs = "";
      let default_fil = [];
      let default_tm = "";
      let rptList = this.state.reportlist;

      if (rptList.length == 0) {
        rptList = reportList;
      }

      for (let i = 0; i < rptList?.length; i++) {
        if (rptList[i].id === parseInt(nam)) {
          this.getTableColumns(rptList[i].table_name);
          // default_fil = [...this.state.reportlist[i].filter.filter];
          this.setState({ reportname: rptList[i].id });
          default_fil = JSON.parse(JSON.stringify(rptList[i].filter.filter));
          default_tm = rptList[i].timeline;
          break;
        }
      }
      if (pp === undefined) {
        fs += '{"formRecordList":[';
        fs += '{"application":{"id":"","value":"loom"}}';
        fs +=
          ',{"table":{"id":"","value":"' +
          "operation_income" +
          '","label":"' +
          "Operation Income" +
          '"}}';
        fs += ',{"records":[]}';
        fs +=
          ',{"page":{"record_count":"0","page_count":"1",' +
          '"page_clicked":"1","page_records":"0"}}';
        fs += ',{"sort":{"asc":"true","column":"id"}}';
        fs += ',{"filter":' + JSON.stringify(default_fil) + "}";
        fs += ',{"timeLine":"' + default_tm + '"}]}';
        this.setState({ filtarray: default_fil, timeline: default_tm });
        this.setFilterString(default_fil);
      } else {
        fs += pp;
      }
      axios
        .post(this.state.loca + `/loom/get/report/${nam}`, fs, {
          headers: {
            authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then(
          (res) => {
            const report = res.data;
            var altColor = this.state.altColor;
            var tml = "";
            if (report !== "") {
              if ("Error" in report) {
                this.setState({
                  loading: false,
                  page_error: true,
                  error: report.Error,
                });
              } else {
                if ("reportRecord" in report) {
                  var newReportData = [];
                  for (
                    var i = 0;
                    i < report.reportRecord[2].record.length;
                    i++
                  ) {
                    newReportData.push({
                      name: report.reportRecord[2].record[i].name,
                      value: parseInt(report.reportRecord[2].record[i].value),
                    });
                  }
                  tml = report.reportRecord[3].reportInformation.timeLine;
                  this.setState({
                    reportdata: newReportData,
                    reporttype: report.reportRecord[3].reportInformation.chart,
                    x_axis: report.reportRecord[4].axis.x_axis,
                    y_axis: report.reportRecord[4].axis.y_axis,
                    reportname: nam,
                    loading: false,
                    initialReport: false,
                    altColor: altColor,
                    default_filter: [...default_fil],
                    default_tm: default_tm,
                  });
                }
              }
            }
          },
          (error) => {
            this.props.showErrorCompo();
          }
        );
    }
  }

  setFilterString = (fltarr) => {
    var filterString = "";
    var leng = fltarr.length;
    for (var f = 0; f < fltarr.length; f++) {
      if (leng === 1 && fltarr[f].cl === "") {
        break;
      } else {
        if (f > 0) {
          filterString += fltarr[f].af + " ";
        }
        filterString += fltarr[f].cl + " ";
        filterString += fltarr[f].mc + " ";
        if (fltarr[f].ct === "reference") {
          filterString += fltarr[f].rf.value + " ";
        } else {
          filterString += fltarr[f].an + " ";
        }
      }
    }
    this.setState({ filterString: filterString });
  };

  calltimeline = (timeline) => {
    this.setState({
      timeline: timeline,
    });
  }

   callfilter = async (filtarray, in_index, col_id) => {
    var rcd = this.state.reportdata;
    if (col_id !== -1) {
      for (let i = 0; i < rcd.length; i++) {
        if (
          filtarray[in_index].ct === "choice" &&
          filtarray[in_index].ch.length === 0
        ) {
          let chc = await this.getChoiceRcd(col_id).then((res) => {
            if (res.length > 0) {
              filtarray[in_index].ch = res;
              filtarray[in_index].an = res[0].name;
            } else {
              filtarray[in_index].ch = [];
            }
          });
        }
      }
    }
    this.setState({
      filtarray: filtarray,
    });
  }

   getChoiceRcd = async (col_id) => {
    var token = localStorage.getItem("token");
    let ck = await axios
      .get(this.state.loca + "/loom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          if ("Error" in res) {
            this.setState({
              loading: false,
              page_error: true,
              error: res.Error,
            });
          } else {
            let chk = res.choiceRecords;
            chk.unshift({ name: "none", value: "None" });
            return chk;
          }
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
    return ck;
  }

  filterSubmit = () => {
    //  let dff = this.state.default_filter;
    //  let addRef = false;
    //  if (dff.length != 0 && (dff[0].an || dff[0].rf.id)) {
    //   addRef = true;
    // }

    //   let filter = this.state.filtarray;
    //   if (addRef) filter[0].af = "AND";

    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","value":"loom"}}';
    fs +=
      ',{"table":{"id":"","value":"' +
      "operation_income" +
      '","label":"' +
      "Operation Income" +
      '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id"}}';
    fs += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    fs += ',{"timeLine":"' + this.state.timeline + '"}]}';
    let len = this.state.filtarray.length;
    if (this.state.filtarray[len - 1].co !== "") {
      if (
        this.state.filtarray[len - 1].an !== "" ||
        this.state.filtarray[len - 1].rf.value !== "" ||
        this.state.filtarray[len - 1].dc.value !== ""
      ) {
        this.callReport(this.state.reportname, fs);
        this.setFilterString(this.state.filtarray);
      }
    } else {
      this.callReport(this.state.reportname, fs);
    }
  };

  filterClear = () => {
    let filter = [];
    let timeline = this.state.default_tm;
    let dff = this.state.default_filter;
    if (dff.length != 0 && (dff[0].an || dff[0].rf.id)) {
      filter = dff;
    } else {
      var flt = this.state.filt;
      flt = [];
      flt.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "",
        rf: { id: "", value: "" },
        dc: { id: "", value: "", label: "" },
      });
      filter = flt;
    }
    this.setState({ timeline: timeline });
    this.setState({ filtarray: filter });
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","value":"loom"}}';
    fs +=
      ',{"table":{"id":"","value":"' +
      "operation_income" +
      '","label":"' +
      "Operation Income" +
      '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id"}}';
    fs += ',{"filter":' + JSON.stringify(filter) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';

    this.callReport(this.state.reportname, fs);
  };

  createNewReport = (type) => {
    if (type === "new") {
      const newWindow = window.open(
        `/private/form?rty=${"new"}&tableName=${"report"}&rid=${0}`,
        `_blank`
      );
      newWindow.onload = () => {
        newWindow.document.title = "Report New";
      };
    } else if (type === "record") {
      if (this.state.reportname !== "") {
        this.setState({ showModal: false });
        const newWindow = window.open(
          `/private/form?rty=${"record"}&tableName=${"report"}&rid=${
            this.state.reportname
          }`,
          `_blank`
        );
        newWindow.onload = () => {
          newWindow.document.title = "Report Record";
        };
      } else {
        this.setState({ page_error: true, error: "Select report" });
      }
    }
  };

  saveReportReplica = () => {
    if (this.state.reportname !== "") {
      let filt = `{"filter":` + JSON.stringify(this.state.filtarray) + `}`;
      let body = {
        records: [
          {
            id: this.state.reportname,
            filter: filt,
            timeline: this.state.timeline,
          },
        ],
      };
      var token = localStorage.getItem("token");
      axios
        .post(this.state.loca + "/loom/save/replica/report", body, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            let res = resp.data;
            if (res !== "") {
              if ("Error" in res) {
                this.setState({
                  loading: false,
                  page_error: true,
                  error: res.Error,
                });
              } else {
                this.setState({
                  loading: false,
                  page_message: true,
                  message: res.Message,
                  showMod: false,
                });
                if (res.report_id !== "") {
                  const newWindow = window.open(
                    `/private/form?rty=${"record"}&tableName=${"report"}&rid=${
                      res.report_id
                    }`,
                    `_blank`
                  );
                  newWindow.onload = () => {
                    newWindow.document.title = "Report Record";
                  };
                }
              }
            }
          },
          (error) => {
            this.props.showErrorCompo();
          }
        );
    } else {
      this.setState({ page_error: true, error: "Select report" });
    }
  };

  saveReport = () => {
    let rptList = this.state.reportlist;
    let repo_id = this.state.reportname;
    let is_admin;
    for (let i = 0; i < rptList?.length; i++) {
      if (rptList[i].id === repo_id) {
        is_admin = rptList[i].is_admin;
      }
    }
    let filt = `{"filter":` + JSON.stringify(this.state.filtarray) + `}`;

    if (repo_id !== "") {
      if (
        (is_admin === "false" && this.state.role !== "admin") ||
        (is_admin === "true" && this.state.role === "admin")
      ) {
        let body = {
          records: [
            {
              id: this.state.reportname,
              filter: filt,
              timeline: this.state.timeline,
            },
          ],
        };
        var token = localStorage.getItem("token");
        axios
          .post(this.state.loca + "/loom/save/report", body, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            let res = resp.data;
            if (res !== "") {
              this.setState({
                loading: false,
                page_message: true,
                message: res.Message,
                showModal: false,
              });
            }
          });
      } else {
        this.setState({
          page_error: true,
          error: "You don't have role to save this report",
        });
      }
    } else {
      this.setState({ page_error: true, error: "Select report" });
    }
  };

  calculateYAxisTicks = () => {
    const values = this.state.reportdata.map((item) => item.value);
    const max = Math.max(...values);
    const tickCount = 5;
    const topDomain = Math.ceil(max * 1.1); // 10% extra space at top
    const interval = topDomain / (tickCount - 1);
    return Array.from({ length: tickCount }, (_, i) =>
      Math.round(i * interval)
    );
  };

  options = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        },
      ],
    },
  };

  render() {
    return (
      <div style={{ height: "80%" }}>
        {this.state.page_message === true && (
          <div
            className="alert alert-success"
            style={{
              padding: "0.2rem 0.2rem",
              margin: "4px 4px 4px 4px",
              color: "black",
            }}
            role="alert"
          >
            {this.state.message}
            <button
              onClick={() => this.setState({ page_message: false })}
              className="cancel-icon"
              aria-label="Close alert"
            >
              &times;
            </button>
          </div>
        )}

        <Modal
          show={this.state.showMod === true}
          onHide={() =>
            this.setState({ showMod: false, page_error: false, error: "" })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Save Replica Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.page_error === true && (
              <div className="alert alert-danger">{this.state.error}</div>
            )}
            {this.state.page_error === false && (
              <div className="alert alert-info" style={{ color: "black" }}>
                Are you sure you want to create replica of this report?
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              onClick={() => this.setState({ showMod: false })}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              style={{
                background: "#0d6efd",
              }}
              onClick={() => {
                this.saveReportReplica();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showModal === true}
          onHide={() =>
            this.setState({ showModal: false, page_error: false, error: "" })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.page_error === true && (
              <div className="alert alert-danger">{this.state.error}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ showModal: false })}
              style={{
                background: "gray",
              }}
            >
              Cancel
            </Button>
            {/* {this.state.filtarray?.some > 0 &&
              this.state.filtarray.map((abc, index) => (
                <span key={index}>
                  {abc.co !== "" && (abc.an !== "" || abc.rf.id !== "") && ( */}
                    <Button
                      variant="primary"
                      onClick={() => {
                        this.saveReport();
                      }}
                      style={{
                        background: "#0d6efd",
                      }}
                    >
                      Save This Report
                    </Button>
                  {/* )}
                </span>
              ))} */}
            <Button
              variant="primary"
              onClick={() => {
                this.createNewReport("record");
              }}
              style={{
                background: "#0d6efd",
              }}
            >
              Open Record
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="table_set over" style={{ textAlign: "center" }}>
          <table className="table table-bordered table-striped  p-1">
            <thead>
              <tr className="obj_name">
                <th>
                  <div className="d-flex justify-content-between">
                    <div>
                      <i
                        className="fa fa-filter vlpointer"
                        aria-hidden="true"
                        onClick={this.filterItem}
                        title="Filter"
                      ></i>
                      {this.state.showFilter === true && (
                        <input
                          className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          type="button"
                          value="Run"
                          onClick={() => {
                            this.filterSubmit();
                          }}
                        ></input>
                      )}
                      {this.state.showFilter === true && (
                        <input
                          className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          type="button"
                          value="Clear"
                          onClick={() => {
                            this.filterClear();
                          }}
                        ></input>
                      )}
                      {this.state.role !== "worker" && (
                        <span>
                          {this.state.reportname !== "" && (
                            <span>
                              <input
                                className="csm_btn csm_btn_pri col-md-2 sub-btn"
                                type="button"
                                value="Save Replica"
                                onClick={() => this.setState({ showMod: true })}
                              ></input>
                              <input
                                className="csm_btn csm_btn_pri col-md-2 sub-btn"
                                type="button"
                                value="Edit Report"
                                onClick={() =>
                                  this.setState({ showModal: true })
                                }
                              ></input>
                            </span>
                          )}
                          <input
                            className="csm_btn csm_btn_pri col-md-2 sub-btn"
                            type="button"
                            value="Create New"
                            onClick={(e) => {
                              this.createNewReport("new");
                            }}
                          ></input>
                        </span>
                      )}
                      <div></div>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div>

        {!this.state.isDashboardinfo &&
          this.state.showFilter === false &&
          this.state.filterString !== "" && (
            <div style={{ marginLeft: "0.5rem" }}>
              {this.state.filterString}
            </div>
          )}

        <div>
          <select
            className=" form-control form-select"
            value={this.state.reportname}
            onChange={(e) => this.callReport(e.target.value)}
            style={{
              margin: "6px 0 10px 6px",
              lineHeight: "1.3",
              width: isMobile ? "60%" : "23%",
            }}
          >
            <option value="None">Select Report</option>
            {this.state.reportlist.length > 0 &&
              this.state.reportlist.map((item, i) => {
                return (
                  <option key={i} value={item.id}>
                    {item.name}
                  </option>
                );
              }, this)}
          </select>
        </div>
        {this.state.showFilter === true && (
          <div className="">
            <NewFilterCompo
              showlist={this.state.showFilter}
              col_mn={this.state.col_mn}
              col_depend={this.state.column_depend}
              call_fil={(filtarray, in_index, col_id) =>
                this.callfilter(filtarray, in_index, col_id)
              }
              filtarray={this.state.filtarray}
              timeline={this.state.timeline}
              call_tm={(timeline) => this.calltimeline(timeline)}
              loca={this.state.loca}
              isMobile={this.state.isMobile}
            ></NewFilterCompo>
          </div>
        )}

        {/* <div>
          <table className="table table-bordered table-striped  p-1">
            <thead>
              <tr>
                <th>
                  <div className="d-flex justify-content-between">
                   
                  </div>
                </th>
              </tr>
            </thead>
          </table>
        </div> */}

        {this.state.loading === true ? (
          <div className="g-compo-loader">
            <WorkInProgress></WorkInProgress>
          </div>
        ) : null}
        {this.state.loading === false &&
        this.state.initialReport === false &&
        this.state.reportlist.length > 0 ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "87vh" }}
          >
            {this.state.reportdata.length === 0 ? (
              <div
                className="text-center p-4"
                style={{
                  backgroundColor: "#f8f9fa",
                  border: "1px solid #dee2e6",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  maxWidth: "80%",
                }}
              >
                <p
                  className="h3 mb-3"
                  style={{
                    color: "#495057",
                    fontWeight: "bold",
                  }}
                >
                  No Data Available
                </p>
                <p
                  className="h5 mb-3"
                  style={{
                    color: "#6c757d",
                  }}
                >
                  There is no data to display for the selected report.
                </p>
                <p
                  style={{
                    color: "#6c757d",
                    fontSize: "1rem",
                  }}
                >
                  Please try adjusting your selection criteria or check the data
                  source.
                </p>
              </div>
            ) : (
              <>
                {this.state.reporttype === "line" && (
                  <div className="question-container">
                    <div
                      style={{
                        width: "100%",
                        overflowX:
                          this.state.reportdata.length > 5 ? "auto" : "hidden",
                        height: "100%",
                      }}
                    >
                      <ResponsiveContainer
                        width={
                          this.state.reportdata.length > 5
                            ? this.state.reportdata.length * 100
                            : "100%"
                        }
                        height="100%"
                        // width={1500}
                        // height={400}
                        // width="85%"
                        // height="85%"
                        // className="container"
                      >
                        {/* <p className="text-center h1">
                      <strong>Rechart Example</strong>
                    </p> */}
                        <LineChart
                          // width={600}
                          // height={400}
                          data={this.state.reportdata}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                          syncId="anyId"
                        >
                          {/* <CartesianGrid
                        stroke="#ccc"
                        strokeDasharray="5 5"
                        fill="#DDDFE2"
                      /> */}
                          <XAxis
                            dataKey="name"
                            angle={-45}
                            textAnchor="end"
                            interval={0} // Show all labels
                            height={60}
                            tick={<CustomizedAxisTick />}
                          >
                            <Label
                              value={this.state.x_axis}
                              offset={39}
                              position="insideTop"
                              scale="point"
                            />
                          </XAxis>
                          <YAxis>
                            <Label
                              value={this.state.y_axis}
                              angle={-90}
                              position="insideLeft"
                            />
                          </YAxis>
                          {/* <Legend
                        verticalAlign="top"
                        // iconType="square"
                        height={36}
                        wrapperStyle={{ top: 5 }}
                        payload={this.state.reportlist.map((item, index) => ({
                          id: item.name,
                          type: "rect",
                          value: `${item.name}`,
                          // color: colors[index % colors.length]
                          color: "#8884d8",
                        }))}
                      /> */}
                          <Tooltip
                            wrapperStyle={{ width: 200 }} // backgroundColor: "red"
                            content={
                              <CustomTooltip
                                reportname={this.state.reportname}
                                reportlist={this.state.reportlist}
                              />
                            }
                          />
                          {/* <Line type="monotone" dataKey="react" stroke="#003153" strokeWidth={2} />
              <Line type="monotone" dataKey="angular" stroke="#8884d8" strokeWidth={2} /> */}
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            strokeWidth={2}
                            label={<CustomizedLabel />}
                            activeDot={{ r: 8 }}
                            animationBegin={0}
                            animationDuration={1500}
                            animationEasing="ease-in-out"
                          />
                        </LineChart>
                        {/* <p className="text-center h4">
                  <strong>LoomWeb Report</strong>
                </p> */}
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}

                {this.state.reporttype === "bar" && (
                  <div className="question-container">
                    {/* <div className="scroll-container"> */}
                    {/* <div
                      style={{
                        width: "100%",
                        height: "100%",
                        overflowX: this.state.reportdata.length > 7 ? "scroll" : "hidden", // Enable horizontal scroll if data length is > 5
                      }}
                    >
                      </div> */}
                    {/* <div className={`${this.state.reportdata.length > 7 ? '"scroll-container' : ''}`}> */}
                    <div
                      style={{
                        width: "100%",
                        overflowX:
                          this.state.reportdata.length > 5 ? "auto" : "hidden",
                        height: "100%",
                      }}
                    >
                      <ResponsiveContainer
                        // width={isMobile ? "90%" : "73%"}
                        // height={isMobile ? "60%" : "80%"}
                        // width={
                        //   this.state.reportdata.length > 7
                        //     ? `${this.state.reportdata.length * 20}%`
                        //     : "100%"
                        // } // Adjust width based on data length
                        // height="100%"
                        // width={2500} height={500}
                        // width="100%" height="100%"
                        width={
                          this.state.reportdata.length > 5
                            ? this.state.reportdata.length * 100
                            : "100%"
                        }
                        height="100%"
                      >
                        <BarChart
                          data={this.state.reportdata}
                          margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 15,
                          }}
                          barCategoryGap="20%" //{10}
                          barGap="20%" //{5}
                        >
                          {/* <CartesianGrid
                        strokeDasharray="4 4"
                        fill="#f0f0f0"
                        fillOpacity={0.7}
                        horizontalCoordinatesGenerator={(props) => {
                          const { height } = props;
                          const count = 5;
                          return Array.from({ length: count - 1 }, (_, index) =>
                            Math.round((height * (index + 1)) / count)
                          );
                        }}
                      /> */}
                          <XAxis
                            dataKey="name"
                            angle={-45}
                            textAnchor="end"
                            interval={0} // Show all labels
                            height={100}
                            tick={<CustomizedAxisTick />}
                            scale="auto"
                          >
                            <Label
                              value={this.state.x_axis}
                              offset={-5}
                              position="insideBottom"
                            />
                          </XAxis>
                          <YAxis
                            padding={{ top: 20 }}
                            // ticks={this.calculateYAxisTicks()}
                            // domain={[0, dataMax => Math.ceil(dataMax * 1.1)]}
                          >
                            <Label
                              value={this.state.y_axis}
                              angle={-90}
                              position="insideLeft"
                            />
                          </YAxis>
                          <Tooltip
                            // wrapperStyle={{ width: 100, backgroundColor: "#ccc" }}
                            content={
                              <CustomTooltip
                                reportname={this.state.reportname}
                                reportlist={this.state.reportlist}
                              />
                            }
                          />
                          <Bar
                            dataKey="value"
                            fill="#8884d8"
                            activeBar={<Rectangle stroke="blue" />} //fill="gold"
                            isAnimationActive={true}
                            animationDuration={600}
                            animationEasing="ease-in-out"
                            barSize={50}
                          >
                            <LabelList dataKey="value" position="top" />
                            {this.state.reportdata.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={bar_colors[index % bar_colors.length]}
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                )}

                {this.state.reporttype === "pie" && (
                  <ResponsiveContainer
                    width={isMobile ? "" : "73%"}
                    height={isMobile ? "100%" : "80%"}
                    // width="50%"
                    // height="60%"
                    className="container"
                  >
                    <PieChart width={600} height={600} className="">
                      {/* <Legend verticalAlign="top" /> */}
                      <Pie
                        data={this.state.reportdata}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={5}
                        // label={this.renderCustomizedLabel}
                        label
                        fill="#8884d8"
                      >
                        {this.state.reportdata.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={pie_colors[index % pie_colors.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip
                        // wrapperStyle={{ width: 100, backgroundColor: "#ccc" }}
                        // content={
                        //   <CustomTooltip
                        //     reportname={this.state.reportname}
                        //     reportlist={this.state.reportlist}
                        //     reporttype={this.state.reporttype}
                        //   />
                        // }
                        payload={this.state.reportlist.map((entry, index) => ({
                          color: pie_colors[index % pie_colors.length],
                        }))}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                )}

                {this.state.reporttype === "horizontalBar" && (
                  // <div className="chart-container">
                  //  <div className="">
                  <ResponsiveContainer
                    width="100%"
                    height="100%"
                    // width="85%"
                    // height="85%"
                    // className="container mrgrepo"
                  >
                    <BarChart
                      layout="vertical"
                      width={500}
                      height={300}
                      data={this.state.reportdata}
                      // barSize={300}
                    >
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}

                      <XAxis type="number" height={60}>
                        <Label
                          value={this.state.y_axis}
                          offset={39}
                          position="insideTop"
                        />
                      </XAxis>
                      <YAxis
                        angle={-45}
                        textAnchor="end"
                        interval={0} // Show all labels
                        dataKey="name"
                        type="category"
                      >
                        <Label
                          value={this.state.x_axis}
                          angle={-90}
                          position="insideLeft"
                        />
                      </YAxis>
                      <Tooltip
                        content={
                          <CustomTooltip
                            reportname={this.state.reportname}
                            reportlist={this.state.reportlist}
                          />
                        }
                      />
                      {/* <Legend
                        verticalAlign="top"
                        wrapperStyle={{ top: 5 }}
                        payload={this.state.reportlist.map((item, index) => ({
                          id: item.name,
                          type: "rect",
                          value: `${item.name}`,
                          // color: bar_colors[index % bar_colors.length]
                          color: "#8884d8",
                        }))}
                      /> */}
                      {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                      <Bar
                        dataKey="value"
                        activeBar={<Rectangle stroke="blue" />} //fill="gold"
                        barSize={40}
                        fill="#8884d8"
                        isAnimationActive={true}
                        animationDuration={600}
                        animationEasing="ease-in-out"
                      >
                        {this.state.reportdata.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={bar_colors[index % bar_colors.length]}
                          />
                        ))}
                        <LabelList position="right" />
                      </Bar>
                      {/* <Line dataKey="uv" stroke="#ff7300" /> */}
                    </BarChart>
                  </ResponsiveContainer>
                  // </div>
                  // </div>
                )}
              </>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const CustomTooltip = ({
  active,
  payload,
  label,
  reportname,
  reportlist,
  reporttype,
}) => {
  if (active && payload && payload.length) {
    const legendItem = reportlist.find((item) => item.name === payload[0].name);
    return (
      <div className="custom-tooltip">
        {/* if ({reporttype !== "pie"}) {
        } */}
        {reporttype !== "pie" && <p className="label">{`${label}`}</p>}
        <p className="valuee">{`${reportname} : ${payload[0].value}`}</p>
        {legendItem && (
          <div className="legend-item">
            {/* <Rectangle fill="#8884d8" width={10} height={10} /> */}
            <span
              className="legend-icon"
              style={{ backgroundColor: "legendItem.color" }}
            />
            <span className="legend-label">{legendItem.name}</span>
          </div>
        )}
      </div>
    );
  }

  return null;
};

class CustomizedLabel extends Component {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={11} textAnchor="middle">
        {value}
      </text>
    );
  }
}

class CustomizedAxisTick extends Component {
  render() {
    const { x, y, stroke, payload } = this.props;
    let val = payload.value;
    let p_val = val.split(` `)[0];
    if (p_val.length > 6) {
      p_val = p_val.substring(0, 6) + "...";
    }
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-10}
          y={0}
          dy={40}
          textAnchor="left"
          fill="#666"
          transform="rotate(-20)"
          style={{ marginTop: "5rem", marginLeft: "15%" }}
        >
          {p_val}
        </text>
      </g>
    );
  }
}

export default WithNavigate(ReportComponentNew);
