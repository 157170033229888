import React, { Component } from "react";
import "../css/listcompo.css";
import list from "../cssMod/listcompo.module.css";
import axios from "axios";
import WorkInProgress from "./work_in_progress";
import PreferenceComponent from "./preference_component";
import { Modal, Button } from "react-bootstrap";
import NewFilterCompo from "./NewFilterCompo";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WithNavigate from "./withNavigate";
import trueImg from "../images/check-mark.png";
import { isMobile } from "react-device-detect";
import GlobalContext from "./GlobalContext ";
import Form_component from "./form_component";
import FormInnerComponent from "./FormInner_Component";
import ApForm from "../ApForm";
import ApUser from "../ApUser";
import MayaAjax from "../MayaAjax";
import ModelList from "./model_list";
import  {getShowMatchFilter} from '../Utility/getShowMatchingFilter.js'
import { message } from "antd";
import {formChange2 ,formChangefnunction ,handleUpdateSaveFn ,handleUpdateCancelFn ,validationFnunction ,handleRowClickFn

  ,handleMouseDownFn ,setcolumnFn ,handleDoubleClickOpenForUpdateFn ,handleMouseUpFn
} from '../Utility/MultiUpdateService.js'
/* getMiddleSign
 tttt:
 // when going to update
    changedRowAndCols  :                      row col-[]
   let refList = this.state.refList;       refrence rcd k liye
    let list = this.state.list;            changed record
    rcd                                     change rows ko push karta hai array me
    har rcd par onSubmit script chali gi
    har rcd ka uivalidation and mandatory check ho ga
    agar sab ka sahi huwa to update hoga



//  multipleSelectedRows
   handleRowClick me use ho ra 


  
  


*/

class ListComponent extends Component {
  static contextType = GlobalContext;

  state = {
    name: "",
    list: [],
    refList: [],
    page_clicked: 0,
    page_count: 0,
    page_records: "",
    record_count: 0,
    showlist: false,
    showbtn: false,
    col_mn: [],
    column_depend: [],
    // value: "",
    showupdownbtn: false,
    int_fields: [],
    date_fields: [],
    email_fields: [],
    boln_fields: [],
    str_fields: [],
    ref_fields: [],
    booleanfld: [],
    tm_list: [],
    filtarray: [],
    mainFilt: {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "", label: "" },
      ch: [],
    },
    filt: {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "", label: "" },
      ch: [],
    },
    filString: "",
    sortColumn: { name: "", sort: true },
    listName: this.props.listName,
    rty: this.props.ty,
    filter: "",
    timeline: "",
    filter_unset: false,
    show: false,
    prefCall: [],
    button: [],
    mainrecord: {},
    tablabel: "",
    page_error: false,
    error: "",
    page_message: false,
    message: "",
    page_script_err: false,
    script_err: "",
    columnid: "",

    showmodel: false,
    noRecord: false,
    lst_record: [],
    loca: this.props.loca,
    srch: "",
    srch_column: "name",
    columnList: [],
    isMobile: this.props.isMobile,
    contextMenu: this.props.contextMenu,
    isDashboardinfo: this.props.isDashboardinfo,
    dashData: this.props.dashData,
    showContext: false,
    loading: true,
    // tableName: "",
    modal: false,
    btnNamee: "",
    url: "",
    isClick: false,
    isMenu: false,
    subMenu: false,
    activeMenu: false,
    listControlls: [],
    filterName: "",
    isMenuMob: false,
    openMenuItem: null,
    isTop: "49.3px",
    dashBoardRecordId: "",
    listRcdInfo: { rcdId: "0", tabName: "" },
    isListRcdInfo: false,
    uiscript: [],
    // multipleSelectedRows: [],
    multiSelectCol:-1,
   multiSelectrow : -1,
    multiRowSelect:new Map(),
    cur_ref_name: "",
    cur_ref_type: "",
    cur_col_index: 0,
    cur_row_index: 0,
    cur_ref_obj: {},
    tabId: "",
    tabName: "",
    notAllowedForUpdate: [
      "filter",
      "filter_ref",
      "other_table_filter",
      "filter_script",
      "json",
      "JSONObject",
      "group_key_value",
      "html",
      "script",
      "multi_select"
    ],
    //changedRowAndCols: new Map(), // rows and []
    record_rq: {},
    ref_filter: [],
    openInNewTab:false,
    newTabRcdId:"0",
    isDragging: false,
    dragStartCell: null,
    hook:""
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.menuX = React.createRef();
    this.tablename = React.createRef();
    this.menuY = React.createRef();
    this.alreadyRun = React.createRef();
    this.ap_form = React.createRef();
    this.ap_user = React.createRef();
    this.filt1 = React.createRef();
    this.listOfTypes = [
      "filter",
      "filter_ref",
      "json",
      "group_key_value",
      "html",
      "reference",
      "multi_select"
    ];
    this.rowsScriptHasBeenRun = React.createRef([]);
    this.multipleSelectedRows = React.createRef([]);
    this.callAginfm=React.createRef(false);
    this.row=React.createRef()
    this.col=React.createRef()
    this.rId=React.createRef()
    this.changedRowAndCols=React.createRef(new Map())
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.filterItem = this.filterItem.bind(this);
    this.setList = this.setList.bind(this);
    this.setList1 = this.setList1.bind(this);
    this.filtersubmit = this.filtersubmit.bind(this);
    this.filterClear = this.filterClear.bind(this);
    this.showupdownbtn = this.showupdownbtn.bind(this);
    this.setSort = this.setSort.bind(this);
    this.callform = this.callform.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.searchColumn = this.searchColumn.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.callSearchbtn = this.callSearchbtn.bind(this);
    this.callfilter = this.callfilter.bind(this);
    this.calltimeline = this.calltimeline.bind(this);
    this.callbtn = this.callbtn.bind(this);
    this.handleCloseRefresh = this.handleCloseRefresh.bind(this);
    this.rightReadOnly = this.rightReadOnly.bind(this);
    this.leftReadOnly = this.leftReadOnly.bind(this);
    this.getChoiceRcd = this.getChoiceRcd.bind(this);
    this.setContext = this.setContext.bind(this);
    this.refresh = this.refresh.bind(this);
    this.menuFn = this.menuFn.bind(this);
    this.selectedRows = this.selectedRows.bind(this);
    this.selectedAllRows = this.selectedAllRows.bind(this);
    this.selectedAction = this.selectedAction.bind(this);
    this.setModal = this.setModal.bind(this);
    this.selectAction = this.selectAction.bind(this);
    // this.getScriptList = this.getScriptList.bind(this);
    // this.getScriptList = this.getScriptList.bind(this);
    this.getListRecord = this.getListRecord.bind(this);
    this.getSortAndFilter = this.getSortAndFilter.bind(this);
    this.storeSrot = this.storeSrot.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.externalRecord = this.externalRecord.bind(this);
    this.state.filtarray.push(this.state.filt);
    this.handleCloseFilterItem = this.handleCloseFilterItem.bind(this);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    console.log("diddddddddddd");
    this.updateSearchParams();
    this._isMounted = true;
   //    document.body.style.cursor = "default"; // reset cursor
    var element = document.getElementsByTagName("body").item(0);
    var element1 = document.getElementById("context_handler");
    let userDetails = localStorage.getItem("userDetails");
    this.ap_user.current=   new ApUser(
      userDetails,
      this.setRecord([])
     )
    element.addEventListener("mousedown", () => {
      if (this._isMounted) {
        this.setState({ showContext: false, isMenu: false, subMenu: false  ,openInNewTab:false});
      }
    });
    element1.addEventListener("wheel", () => {
      if (this._isMounted) {
        this.setState({ showContext: false, isMenu: false, subMenu: false });
      }
    });
    if (this.state.isDashboardinfo) {
      let dashBoardRecordId = this.props.dashBoardRecordId;
      this.setState({ dashBoardRecordId: dashBoardRecordId });
      this.setList1(this.state.dashData);
    } else this.getSortAndFilter(); //getSortAndFilter
  }

 
  componentWillUnmount() {
    this._isMounted = false;
  }

  updateSearchParams = (nextProps) => {
    console.log("innnnnnnnnnnnnnnnnnn");
    
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    let tableName = searchParams.get("tableName");
    let filt = searchParams.get("filter");
    
    // let { filter } = location?.state;
    let  filter  = location?.state?.filter;
    if (!filter) filter = [];
    if(filt) {
      filter = JSON.parse(filt);
      this.filt1.current = filter;
    }
    console.log("secccccc: ",this.filt1.current);
    console.log("secccccc2: ",this.state.filter);
    this.setState(
      {
        listName: tableName,
        filter: filter,
      },
      () => {
        // Callback to log the updated state after setState
      }
    );
  };
  componentDidUpdate(nextProps) {

    // if (this.state.isDragging && !nextProps.isDragging) {
    //   document.body.style.cursor = "copy"; // change cursor to '+'
    // } else if (!this.state.isDragging && nextProps.isDragging) {
    //   document.body.style.cursor = "default"; // reset cursor
    // }

    if (this.props.location.search !== nextProps.location.search) {
      this.updateSearchParams(nextProps);
    }

    if (this.props.dashBoardRecordId != this.state.dashBoardRecordId) {
      if (this.state.isDashboardinfo) {
        let dashBoardRecordId = this.props.dashBoardRecordId;
        this.setState({
          dashBoardRecordId: dashBoardRecordId,
          dashboardData: this.props.dashboardData,
        });
        this.setList1(this.props.dashData);
      }
    }

    if (
      /*   (nextProps.listName !== this.state.listName ||
        nextProps.filter !== this.state.filter) && */ this.props.location
        .search !== nextProps.location.search
    ) {
      if (this.state.isDashboardinfo) {
        this.setList1(this.state.dashData);
      } else this.getSortAndFilter();
    }
    const innerText = this.myRef.current;
    if (innerText) {
      if (!this.state.modal) {
        innerText.value = "none";
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    let tableName = searchParams.get("tableName");

    let filter = location.state?.filter || [];
    let tm = location.state?.timeLine || "";
    if (filter.length === 0) {
      filter = [];
      filter.push(state.filt);

      filter = { filter: filter, timeline: "" };
    }

    if (tableName !== state.listName) {
      return {
        listName: tableName,
        filter: filter,
        column_depend: [],
        isMobile: props.isMobile,
        tableName: props.listName,
        timeline: tm,
      };
    } else if (tableName === state.listName) {
      if (JSON.stringify(filter) !== JSON.stringify(state.filter)) {
        return {
          filter: filter,
          column_depend: [],
          isMobile: props.isMobile,
          tableName: props.listName,
          // timeline: ""
        };
      }
    }
    if (state.contextMenu === props.contextMenu) {
      return {
        contextMenu: props.contextMenu,
      };
    } else if (state.contextMenu !== props.contextMenu) {
      return {
        contextMenu: props.contextMenu,
      };
    }

    return null;
  }



  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }
  handleModalList = () => {
    this.setState({ showmodel: false });
  };

  openModalList() {
    this.setState({ showmodel: true });
  }

  setRef = (val, ref_id) => {
    // columnid: col,
    // cur_ref_name: name,
    // cur_ref_type: type,
    // cur_col_index: index,
    // cur_row_index:obj.rowIndex,
    // cur_ref_obj:obj,
    // showmodel: true,
    console.log("setRefcalled");

    this.setRefrecord(
      val,
      this.state.cur_col_index,
      this.state.cur_ref_name,
      this.state.cur_ref_type,
      ref_id
    );
    this.handleModalList();
  };

  setRefrecord = (vl, col_ind, ob, type, id) => {
    var uiScript = this.state.uiscript;
    // for (let i = 0; i < uiScript.length; i++) {
    //   let field = uiScript[i].field.name;
    //   let func = uiScript[i].script;
    //   let type = uiScript[i].type;
    //   //script
    //   if (field === ob && type === "onreference") {
    //     // this.onReference(func, row_ind);
    //   }
    // }
    formChangefnunction({thiss:this,
      vl,
      index:col_ind,
      ob,
      type,
      id,
      vrf:true,
      readonly:undefined,
      outerIndex:undefined,
      innerIndex:"",
      nme:"",
      obj:this.state.cur_ref_obj,axios}
    );

    // formChangefn = (
    //   vl /* value */,
    //   index /* index */,
    //   ob /* filed */,
    //   type,
    //   id,
    //   vrf,
    //   readonly,
    //   outerIndex,
    //   innerIndex,
    //   nme,
    //   obj,
    //   rcd_info
    // )
  };

  handleCloseRefresh() {
    this.setState({ show: false });
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","value":"loom"}}';
    tablere +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    tablere += ',{"records":[]}';
    var sarray = this.state.sortColumn;
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    if (sarray.sort === true) {
      tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    } else {
      tablere += ',{"sort":{"asc":"false","column":"id","init":"true"}}';
    }

    tablere += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    tablere += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(tablere);
    // this.getSortAndFilter();
  }

  handleCloseFilterItem() {
    this.setState({ showlist: false  ,filterName:""});
  }

  leftReadOnly() {
    if (this.state.page_clicked === 1) {
      return true;
    } else {
      return false;
    }
  }

  rightReadOnly() {
    if (this.state.page_clicked === this.state.page_count) {
      return true;
    } else {
      return false;
    }
  }

  previousPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked - 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  nextPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked + 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(pp);
  }

  firstPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  lastPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"0","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.record_count % 50 === 0
        ? this.state.page_count - 1
        : this.state.page_count) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  externalRecord() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(pp);
  }

  showupdownbtn(hd) {
    var sarray = this.state.sortColumn;
    let flt = JSON.parse(JSON.stringify(this.state.filtarray));

    var clmn = "";
    var srt = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          if (this.state.list[l].heading[ll].name === hd) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        srt += '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      } else {
        srt +=
          '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < this.state.list.length; li++) {
        for (var lll = 0; lll < this.state.list[li].heading.length; lll++) {
          if (this.state.list[li].heading[lll].name === hd) {
            clmn = this.state.list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        srt += '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      } else {
        srt +=
          '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
      }
    }

    let len = this.state.filtarray.length;
    if (this.state.filtarray[len - 1].co !== "") {
      if (
        this.state.filtarray[len - 1].an !== "" ||
        this.state.filtarray[len - 1].rf.value !== "" ||
        this.state.filtarray[len - 1].dc.value !== ""
      ) {
        pp += ',{"filter":' + JSON.stringify(flt) + "}";
        pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
        this.storeSrot(srt, pp);
      } else {
        let fill = [];
        fill.push(this.state.filt);
        this.setState({ filtarray: fill });
        pp += ',{"filter":' + JSON.stringify(fill) + "}";
        pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
        this.storeSrot(srt, pp);
      }
    } else {
      pp += ',{"filter":' + JSON.stringify(flt) + "}";
      pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
      this.storeSrot(srt, pp);
    }
  }

  setSort() {
    var clmn = "";
    var pp = "";
    var srt = this.state.sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          if (
            this.state.list[l].heading[ll].name === this.state.sortColumn.name
          ) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }
      if (this.state.sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        this.state.list[0].heading[1].name +
        '","init":"false"}}';
    }
    return pp;
  }

  setList(tablere) {
    var token = localStorage.getItem("token");
    this.setState({ loading: true });
    axios
      .post(this.state.loca + "/loom/get/multiplerecord", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          this.setList1(listrecord);
          console.log("listRcd", listrecord);
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
  }

  setList1(listrecord) {
    console.log("listRecord", listrecord);

    var columnarry = [];
    var hd = [];
    var va_ll = [];
    var col_list = [];
    this.multipleSelectedRows.current=[];
    if (listrecord !== "") {
      if ("Error" in listrecord) {
        this.setState({
          loading: false,
          page_error: true,
          error: listrecord.Error,
        });
      } else {
        var scnd = listrecord.formRecordList[2];
        if ("Error" in scnd) {
          if (scnd.Error === "No record found.") {
            this.setState({ list: [] });
          }
        } else {
          var page_clicked1 = parseInt(
            listrecord.formRecordList[3].page.page_clicked
          );
          var page_count1 = parseInt(
            listrecord.formRecordList[3].page.page_count
          );
          var page_records1 = listrecord.formRecordList[3].page.page_records;
          var record_count1 = parseInt(
            listrecord.formRecordList[3].page.record_count
          );

          var flt = listrecord.formRecordList[5];
          var fltarr = listrecord.formRecordList[5]?.filter;
          var tmln = listrecord.formRecordList[6]?.timeLine;

          var filterString = "";
          var leng = fltarr.length;
          for (var f = 0; f < fltarr.length; f++) {
            if (leng === 1 && fltarr[f].cl === "") {
              break;
            } else {
              if (f > 0) {
                filterString += fltarr[f].af + " ";
              }
              filterString += fltarr[f].cl + " ";
              filterString += fltarr[f].mc + " ";
              if (fltarr[f].ct === "reference") {
                filterString += fltarr[f].rf.value + " ";
              } else {
                filterString += fltarr[f].an + " ";
              }
            }
          }
          if (listrecord.formRecordList[2].records.length === 0) {
            this.setState({ noRecord: true });
          } else {
            this.setState({ noRecord: false });
          }
          for (
            var i = 0;
            i < listrecord.formRecordList[2].records.length;
            i++
          ) {
            var in_vl = [];
            var record_id;
            for (
              var j = 0;
              j < listrecord.formRecordList[2].records[i].record.length;
              j++
            ) {
              if (j === 0) {
                record_id =
                  listrecord.formRecordList[2].records[i].record[j].value;
              } else if (j === 1) {
                // if (
                //   listrecord.formRecordList[2].records[i].record[j].type ===
                //   "reference"
                // ) {
                //   in_vl.push({
                //     value:
                //       listrecord.formRecordList[2].records[i].record[j].value
                //         .value,
                //     type: listrecord.formRecordList[2].records[i].record[j]
                //       .type,
                //     firstrecord: true,
                //     table: listrecord.formRecordList[1].table.value,
                //     r_id: record_id,
                //     columnAccess:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .columnAccess,
                //     displayColumn:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .displayColumn,
                //     uivalid:
                //       listrecord.formRecordList[2].records[i].record[j].uivalid,
                //     validation:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .validation,
                //     name: listrecord.formRecordList[2].records[i].record[j]
                //       .name,
                //     id: listrecord.formRecordList[2].records[i].record[j].id,
                //     rowIndex: i,
                //     isInPref:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .isInPref,
                //     choice:
                //       listrecord.formRecordList[2].records[i].record[j].choice,
                //   });
                // } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                  columnAccess:
                    listrecord.formRecordList[2].records[i].record[j]
                      .columnAccess,
                  displayColumn:
                    listrecord.formRecordList[2].records[i].record[j]
                      .displayColumn,
                  uivalid:
                    listrecord.formRecordList[2].records[i].record[j].uivalid,
                  validation:
                    listrecord.formRecordList[2].records[i].record[j]
                      .validation,
                  name: listrecord.formRecordList[2].records[i].record[j].name,
                  id: listrecord.formRecordList[2].records[i].record[j].id,
                  rowIndex: i,
                  isInPref:
                    listrecord.formRecordList[2].records[i].record[j].isInPref,
                  choice:
                    listrecord.formRecordList[2].records[i].record[j].choice,
                  label:
                    listrecord.formRecordList[2].records[i].record[j].label,
                });
                //                }
              } else {
                // if (
                //   listrecord.formRecordList[2].records[i].record[j].type ===
                //   "reference"
                // ) {
                //   in_vl.push({
                //     value:
                //       listrecord.formRecordList[2].records[i].record[j].value
                //         .value,
                //     type: listrecord.formRecordList[2].records[i].record[j]
                //       .type,
                //     firstrecord: false,
                //     table: listrecord.formRecordList[1].table.value,
                //     r_id: record_id,
                //     columnAccess:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .columnAccess,
                //     displayColumn:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .displayColumn,
                //     uivalid:
                //       listrecord.formRecordList[2].records[i].record[j].uivalid,
                //     validation:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .validation,
                //     name: listrecord.formRecordList[2].records[i].record[j]
                //       .name,
                //     id: listrecord.formRecordList[2].records[i].record[j].id,
                //     rowIndex: i,
                //     isInPref:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .isInPref,
                //     choice:
                //       listrecord.formRecordList[2].records[i].record[j].choice,
                //   });
                // } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                  columnAccess:
                    listrecord.formRecordList[2].records[i].record[j]
                      .columnAccess,
                  displayColumn:
                    listrecord.formRecordList[2].records[i].record[j]
                      .displayColumn,
                  uivalid:
                    listrecord.formRecordList[2].records[i].record[j].uivalid,
                  validation:
                    listrecord.formRecordList[2].records[i].record[j]
                      .validation,
                  name: listrecord.formRecordList[2].records[i].record[j].name,
                  id: listrecord.formRecordList[2].records[i].record[j].id,
                  rowIndex: i,
                  isInPref:
                    listrecord.formRecordList[2].records[i].record[j].isInPref,
                  choice:
                    listrecord.formRecordList[2].records[i].record[j].choice,
                    label:
                    listrecord.formRecordList[2].records[i].record[j].label,
                });
                //             }
              }
            }
            if (this.state.isDashboardinfo === true) {
              va_ll.push({ colr: in_vl });
            } else {
              in_vl.splice(0, 0, { ref: false, isInPref: true });
              va_ll.push({ colr: in_vl });
            }
          }
          for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
            if (listrecord.formRecordList[9].column[c].type === "String") {
              col_list.push({
                label: listrecord.formRecordList[9].column[c].label,
                name: listrecord.formRecordList[9].column[c].name,
                type: listrecord.formRecordList[9].column[c].type,
              });
            }
          }
          for (
            var p = 0;
            p < listrecord.formRecordList[10].preference.length;
            p++
          ) {
            hd.push({
              label: listrecord.formRecordList[10].preference[p].label,
              name: listrecord.formRecordList[10].preference[p].name,
            });
          }
          if (this.state.isDashboardinfo === true) {
            columnarry.push({ heading: hd, rcd: va_ll });
          } else {
            hd.splice(0, 0, { ref: false });
            columnarry.push({ heading: hd, rcd: va_ll });
          }
          var cla = JSON.parse(
            JSON.stringify(listrecord.formRecordList[9].column)
          );

          console.log("columnarry", columnarry);
          let jj = JSON.parse(JSON.stringify(columnarry));
          cla.unshift({ label: "None", name: "none" });

          this.setState({
            tablabel: listrecord.formRecordList[1].table.label,
            loading: false,
            list: columnarry,
            refList: jj,
            page_clicked: page_clicked1,
            page_count: page_count1,
            page_records: page_records1,
            record_count: record_count1,
            col_mn: cla,
            prefCall: listrecord.formRecordList[9].column,
            filter: flt,
            filtarray: fltarr,
            timeline: tmln,
            filString: filterString,
            button: listrecord.formRecordList[12].button,
            uiscript: listrecord.formRecordList[11].uiscript,
            columnList: col_list,

            //multipleSelectedRows: [],
         
            tabId: listrecord.formRecordList[1].table.id,
            tabName: listrecord.formRecordList[1].table.value,
            // ap_user: new ApUser(userDetails, this.setRecord("")),
          });
          //this.ap_form.current=  new ApForm(columnarry.rcd, this.setRecord(""), {}, this)
          this.checkRefrecord();
        }
      }
    }
  }

  async checkRefrecord() {
    var token = localStorage.getItem("token");
    axios
      .get(
        this.state.loca +
          "/loom/get/reference/qualifier/" +
          this.state.listName,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          const rq_data = resp.data;
          if (rq_data !== "") {
            this.setState({ record_rq: rq_data });
            if (this.uiscript) {
              let script = this.uiscript.current;
            }
          }
        },
        (error) => {
          let err = {
            message: error.message,
            code: error.response.status,
          };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
  }

  callform(e, nam, tab, r_id) {
    const { navigateWithSearchParams } = this.props;
    if (nam === "first") {
      let pg = {
        page: this.state.page_clicked,
        filter: this.state.filtarray,
        sort: this.state.sortColumn,
        timeline: this.state.timeline,
      };
      // localStorage.setItem("pageClicked", JSON.stringify(pg));

      this.context.pushInListOfFilters(tab, pg);
      let lab = this.state.tablabel;
          
      //  this.props.showFormCompo(tab, r_id, "record");
      //  this.props.navigate("/private/form")

      if (e.button === 0) {
        navigateWithSearchParams(
          "/private/form",
          {
            rty: "record",
            tableName: tab,
            rid: r_id,
          },
          {}
        );
        document.title = lab + " Record";
      } else if (e.button === 1) {
         // Middle mouse button
        const newWindow = window.open(`/private/form?rty=record&tableName=${tab}&rid=${r_id}`,
          `_blank`);
          newWindow.onload = () => {
            newWindow.document.title = lab + " Record";
          };
        

        // _balnk is open new tab
        // e.preventDefault();

        // const newTab = window.open("about:blank", "_blank");
        // newTab.location.href = `/private/form?rty=record&tableName=${tab}&rid=${r_id}`;
        // newTab.onload = () => {
        //   console.log("newTabbbbbbb: ");
        //   newTab.document.title = "kkkk"; // Change the title
        // };
      //   newTab.addEventListener("load", function() {
      //     newTab.document.title = "kkkk";
      // });
      } else  if (e.ctrlKey && e.button === 0) {
        const newWindow = window.open(`/private/form?rty=record&tableName=${tab}&rid=${r_id}`,
          `_blank`);
         newWindow.onload = () => {
           newWindow.document.title = lab + " Record";
         };
       }
    } else if (nam === "second") {
    } else if (nam === "new") {
      this.props.showFormCompo(tab, 0, "new");

      navigateWithSearchParams(
        "/private/form",
        {
          rty: "new",
          tableName: tab,
          rid: 0,
        },
        { tabTitle: tab }
      );
    }
  }

   setListContext=(val, x, y, obj) => {
    if (val === 2) {
      this.menuX.current = x;
      this.menuY.current = y;
     // setRecordId(rid);
     // setShowListContext(true);
     this.setState({openInNewTab:true ,newTabRcdId:obj.rid})
//    { rid:objr.r_id ,row:objj_i ,col:objr_i}
     this.row.current=obj.row
     this.col.current =obj.col
     this.rId.current = obj.rid
     // setShowListContext(false);
  
    }else{
      this.setState({openInNewTab:false})
    }
  };

  openInNewTabFn=()=>{
    let lab = this.state.tablabel;
   let newWindow = window.open(
      `/private/form?rty=record&tableName=${this.state.listName}&rid=${this.state.newTabRcdId}`,
      `_blank`
    );
    newWindow.onload = () => {
      newWindow.document.title = lab + " Record";
    };
  }

  handleDoubleClickOpenForUpdate = (rowIndex, colCell) => {
    handleDoubleClickOpenForUpdateFn({rowIndex, colCell ,thiss:this})
  };

  // runScriptOnLoadWhenUpdate = (rowIndex, colCell) => {
  //   let script = this.state.uiscript;
  //   let rowRecord = this.state.list[0].rcd[rowIndex].colr;
  //   let userDetails = localStorage.getItem("userDetails");

  //   console.log("rowRecord on load", rowRecord);

  //   if (script !== null) {
  //     for (let i = 0; i < script.length; i++) {
  //       let func = script[i].script;
  //       let type = script[i].type;
     

  //       if (type === "onload") {
  //         console.log("func " + i, func);
  //         this.onLoad(
  //           func,
  //            new ApUser(
  //             userDetails,
  //             this.setRecord(rowRecord)
  //            ),
          
  //           new ApForm(rowRecord, this.setRecord(rowRecord), this),{}
  //         );
  //       }
  //     }
  //   }
  // };
  // this.onLoad(
  //   func,
  //    new ApUser(
  //     userDetails,
  //     this.setRecord(rowRecord)
  //    ),
  //   {}
  //   new ApForm(rowRecord, this.setRecord(rowRecord), this)
  // );
  // onLoad = (func, user, form, val) => {
  //   let fn = new Function(["ap_user", "ap_form", "val", "MayaAjax"], func);
  //   fn(user, form, val, MayaAjax);
  // };

  handleUpdateSave = (rowIndex, colCell) => {
 
    handleUpdateSaveFn({rowIndex, colCell,thiss:this ,axios});
  };

  handleUpdateCancel = (rowIndex, colCell) => {
    handleUpdateCancelFn({rowIndex, colCell,thiss:this})
  };

  handleRowClick = (e, rowIndex ,colCel) => {
      handleRowClickFn({e,rowIndex,colCel,thiss:this})
  };


  handleMouseDown = (rowIndex, colIndex, event) => {
    handleMouseDownFn({rowIndex, colIndex, event,thiss:this ,document})
  };

  handleMouseUp = () => {
    // this.setState({
    //   isDragging: false,
    //   dragStartCell: null,
    // });
    //      // Re-enable text selection
    // document.body.style.userSelect = "auto";

    // // Reset cursor back to default
    // document.body.style.cursor = "default";
    handleMouseUpFn({thiss:this , document})
  
  };


  handleMouseOver = (rowIndex, colIndex) => {
    const { isDragging, dragStartCell } = this.state;

   
    
    if (isDragging && dragStartCell) {

      const selected = this.multipleSelectedRows.current;
      for (
        let i = Math.min(dragStartCell.rowIndex, rowIndex);
        i <= Math.max(dragStartCell.rowIndex, rowIndex);
        i++
      ) {
        if (!selected.includes?.(i))
           selected.push( i);
      }
     // this.setState({ selectedCells: selected })
     this.multipleSelectedRows.current=selected
    }

    
  };

   pushInMultiRowSelect=(row ,col)=>{
    let multiRowSelect = new Map(this.state.multiRowSelect);
    // pushInListOfFilters = (tabName, obj) => {
    //   this.setState((prevState) => {
    //     const updatedFilters = new Map(prevState.listOfFilters);
    //     updatedFilters.set(tabName, obj);
    //     return { listOfFilters: updatedFilters };
    //   });
    
      
    // }
    if(multiRowSelect.get(row)) {
      //  let arr =multiRowSelect.get(row);
       // arr.push(col)
        multiRowSelect.set(row,col)
    }else{
      // let arr=[]
      // arr.push(col)
       multiRowSelect.set(row,col)
    }
     this.setState({multiRowSelect:multiRowSelect});
     console.log("multiRowSelect" ,multiRowSelect);
     
   }

   popInMultiRowSelect=(row ,col)=>{
    let multiRowSelect = new Map(this.state.multiRowSelect);
     multiRowSelect.delete(row);
     this.setState({multiRowSelect:multiRowSelect})
   }

   isColSelected=(row,col)=>{
    // let multiRowSelect = new Map(this.state.multiRowSelect);

    
    // if(multiRowSelect.get(row)){
    //    if(col==multiRowSelect.get(row))
    //     return true;
    // }
    // return false;
    if(!this.multipleSelectedRows.current){
      this.multipleSelectedRows.current=[]
    }
   let  newMultipleSelectedRows  =this.multipleSelectedRows.current;
  //  console.log("isColSelected" ,newMultipleSelectedRows);
   // console.log("row" ,row ,col , this.state.multiSelectCol);
   if(col=== this.state.multiSelectCol && newMultipleSelectedRows?.includes(row)
   )
  return true;
  return false;
   }

  formInner = (objr, ot) => {

    return (
      <FormInnerComponent
        obj={objr}
        m_rid={ot?.recordId}
        index={ot.colIndex}
        rowIndex={ot?.rowIndex}
        multiUpdate={true}
        //   key={ objr_i}
        //   record={this.state.record}
        //   tabname={this.tablename.current}
        validationfn={this.validationfn}
        formChangefn={this.formChangefn}
        //   setRefrecord={this.setRefrecord}
        setcolumn={this.setcolumn}
        //   calltimeline={this.calltimeline}
        //   callfilter={this.callfilter}
        //   callScript={this.callScript}
        //   deleteImage={this.deleteImage}
        //   handleFileChange={this.handleFileChange}
        //   launchChoose={this.launchChoose}
        //   setMSC={this.setMSC}
        //   refrecord={this.state.refrecord}
        //   choice_mn={this.state.choice_mn}
        //   showlist={this.state.showlist}
        //   col_mn={this.state.col_mn}
        //   col_depend={this.state.column_depend}
        //   filtarray={this.state.filtarray}
        //   timeline={this.state.timeline}
        //   loca={this.state.loca}
        //   tabId={this.state.tabId}
        //   isMobile={this.state.isMobile}
        //   column_other={this.state.column_other}
        //   reScript={this.state.reScript}
        //   editor={this.state.editor}
        //   mscList={this.state.mscList}
        //   setContextMenu={this.setContextMenu}
        //   getSingleInfo={this.getSingleInfo}
        //   validation={this.state.validation}
        //   validation_error={this.state.validation_error}
        //   verify_error={this.state.verify_error}
        //   ob={this.state.ob}
        //   setref_filter={this.state.filtRefArray}
        //   col_mn_ref={this.state.column_ref}
        //   keyValueJson={this.state.keyValueJson}
        //   groupkeyValue={this.state.groupkeyValue}
        //   isRecent={this.state.isRecent}
        //   showRecent={this.showRecent}
      ></FormInnerComponent>
    );
  };

  getShowMatchingList =()=>{

    let row = this.row.current
    let col = this.col.current
    let rid= this.rId.current;

    let otb = this.state.list;
    let singleColumn = otb[0].rcd[row].colr[col];
let column={currentFilter:this.state.filtarray , cl:singleColumn.label,
  co:singleColumn.name,type:singleColumn.type,an:singleColumn.value
}

if(singleColumn.type==="choice"){
 // let arr= singleColumn
column.ch=singleColumn.choice;
column.ch.unshift(
  {name: "none", value: "None"});
  
}
    console.log("fitarr" ,this.state.filtarray);
    console.log("singleColumn" ,singleColumn);
    console.log("timeline" ,this.state.timeline);
    

    getShowMatchFilter(column);

    console.log("after getting" ,column);
    this.setState({filtarray:column.currentFilter})
    
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';

    pp += ',{"records":[]}';
    pp +=
    ',{"page":{"record_count":"0","page_count":"1",' +
    '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(column.currentFilter) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
   this.setList(pp);

     
  }

  formChangefn = (
    vl /* value */,
    index /* index */,
    ob /* filed */,
    type,
    id,
    vrf,
    readonly,
    outerIndex,
    innerIndex,
    nme,
    obj,
    rcd_info
  ) => {


       formChangefnunction({thiss:this ,    vl /* value */,
        index /* index */,
        ob /* filed */,
        type,
        id,
        vrf,
        readonly,
        outerIndex,
        innerIndex,
        nme,
        obj,
        rcd_info})
  };

  setcolumn = (index, name, type, col, readonly, obj) => {
    setcolumnFn({index, name, type, col, readonly, obj,thiss:this})
  };

  getFieldValue = (col_id, obj) => {
    //   var rcd = this.state.record;
    let otb = this.state.list;
    var rcd = otb[0].rcd[obj.rowIndex].colr;
    if (rcd !== "null" && rcd !== "") {
      for (var r = 0; r < rcd.length; r++) {
        if (rcd[r].id === col_id) {
          return rcd[r].value;
        }
      }
    }
  };

  validationfn = (vl, index, ob, type, id, obj) => {
    validationFnunction({vl, index, ob, type, id, obj,thiss:this,axios})
  };

  fieldverify = (type, vl) => {
    // this.setState({ page_error: false, error: "", page_message: false, message: "" });
    if (type === "String") {
      return "verified";
    }
    if (type === "email") {
      if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "int") {
      if (/^[0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "date") {
      if (/^[+-]?\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "time") {
      if (/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "reference") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "filter") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "aadhar_number") {
      if (/\d{12}/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
  };

  // scriptFunc = (ob, type, val, index) => {
  //   var uiScript = this.state.uiscript;
  //   console.log("uiScript", this.state.uiscript);

  //   // for (let i = 0; i < uiScript.length; i++) {
  //   //   let field = uiScript[i].field.name;
  //   //   let func = uiScript[i].script;
  //   //   let ui_type = uiScript[i].type;
  //   //   //script
  //   //   if (type === "choice") {
  //   //     if (field === ob && ui_type === "onchange") {
  //   //       this.onChange(func, val, index);
  //   //     }
  //   //   } else {
  //   //     if (field === ob && ui_type === "onchange") {
  //   //       console.log("onChange");

  //   //       this.onChange(func, val, index);
  //   //     }
  //   //     if (field === ob && ui_type === "oncelledit") {
  //   //       this.onCellEdit(func, val, index);
  //   //     }
  //   //   }
  //   // }
  // };









  // runForEachRecord = async (rcd, btn) => {
  //   let uiScript = this.state.uiscript;

  //  let onSubmitResponse = false;
  //   for (let i = 0; i < uiScript.length; i++) {
  //     let func = uiScript[i].script;
  //     let type = uiScript[i].type;

  //     if (type === "onsubmit") {
  //       onSubmitResponse = await this.onSubmit(func, btn.name); // agar tum ko button k task rokna hai to true send karo
  //       if (onSubmitResponse instanceof Promise) {
  //         let pro = onSubmitResponse;
  //         pro.then((res) => {
  //           onSubmitResponse = res;
  //         });
  //       }

  //       if (onSubmitResponse)
  //         // if there is more than one script ,if any one return true  then
  //         // next script will not get executed
  //         break;
  //     }
  //   }

  //   rcd = JSON.parse(rcd);

  //   rcd = rcd.formRecord[2].record;
  //   let rcdId=-1;
  //   let idFound=false;
  //   console.log("rcdd", JSON.parse(JSON.stringify(rcd)));
  //   for (var i = 0; i < rcd.length; i++) {
  //     if (rcd[i]?.uivalid?.visible === "true" && rcd[i]?.type !== "boolean") {
  //       if (rcd[i]?.uivalid?.mandatory === "true") {
  //         if (rcd[i].value === "") {
  //             mandatory.push(rcd[i].label);
          
  //         } else if (rcd[i].type === "reference" && rcd[i].value.value === "") {
  //             mandatory.push(rcd[i].label);
          
  //         } else if (
  //           rcd[i].type === "multi_select" &&
  //           rcd[i].value.record.length === 0
  //         ) {
  //            mandatory.push(rcd[i].label);
          
  //         } else if (
  //           typeof rcd[i].value === "string" &&
  //           rcd[i].value.trim() === ""
  //         ) {
  //            mandatory.push(rcd[i].label);
        
  //         }
  //       }

  //       if (
  //         rcd[i]?.type === "String" ||
  //         rcd[i]?.type === "int" ||
  //         rcd[i]?.type === "email" ||
  //         rcd[i]?.type === "date" ||
  //         rcd[i]?.type === "reference"
      
  //       ) {
  //         var msnd = rcd[i].uivalid.mandatory;
  //         var minLength = rcd[i].uivalid.min_length;
  //         var veri = rcd[i]?.verified;
  //         if (veri === "unverified" || veri === "min_unverified") {
  //             unverified.push(rcd[i]?.label);
  //         }
  //       }

  //       if(rcd[i].name==="id" && !idFound){
  //         rcdId=rcd[i].value;
  //         idFound=true;
  //       }

  //     }
  //   }

  //   return {
  //     onSubmitResponse: onSubmitResponse,
  //     unverified: unverified,
  //     mandatory: mandatory,
  //     rcdId:rcdId
  //   };
  // };

  setRecord(value) {
    // this.setState({ recordd: value });
    // return "record";
  }

  // onChange = (func, val, row_ind) => {
  //   let fn = new Function(
  //     ["ap_user", "ap_form", "val", "index", "MayaAjax"],
  //     func
  //   );
  //   fn(this.state.ap_user, this.state.ap_form, val, row_ind, MayaAjax);
  // };

  // onChange(func, val) {
  //   console.log("onCha " ,this.ap_form.current);
    
  //   let fn = new Function(["ap_user", "ap_form", "val", "MayaAjax"], func);
  //   fn(this.ap_user.current, this.ap_form.current, val, MayaAjax);
  // }

  onCellEdit = (func, val, row_ind) => {
    let fn = new Function(
      ["ap_user", "ap_form", "val", "index", "MayaAjax"],
      func
    );
    fn(this.ap_user.current, this.ap_form.current, val, row_ind, MayaAjax);
  };

  onSubmit(func, btnName) {
    let fn = new Function(["ap_user", "ap_form", "MayaAjax", "btnName"], func);
    let res = fn(this.ap_user.current, this.ap_form.current, MayaAjax, btnName);
    return res;
  }

  // onLoad = (func, user, form) => {
  //   let fn = new Function(["ap_user", "ap_form", "MayaAjax"], func);
  //   fn(user, form, MayaAjax);
  // };
  getFieldValueOfUiScript = (name, rcd) => {
    //  const frecord = rcd[index].record;
    // let otb = this.state.list;
    let frecord = rcd;

    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === name) {
        return frecord[i].value;
      }
    }
  };

  getReadonlyOfUiScript = (name, rcd) => {
    const frecord = rcd;
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === name) {
        return frecord[i].uivalid.read_only;
      }
    }
  };

  setFieldValueOfUiScript = (name, value, id, rcd) => {
    //  const frecord = rcd[index].record;
    let otb = this.state.list;
    let frecord = rcd;
    console.log("frecord2" , JSON.parse(JSON.stringify(frecord)));
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].type === "reference" || frecord[i].type === "depend_table") {
        if (frecord[i].name === name) {
          frecord[i].value.value = value;
          if (!id) {
            frecord[i].value.id = "0";
          } else {
            frecord[i].value.id = id;
          }
        // frecord[i].value.id = id;
          // if (this._isMounted) {
          this.setState({
            list: otb,
          });
          //   }
        }
      } else {
        if (frecord[i].name === name) {
          frecord[i].value = value;
          // if (this._isMounted) {
          this.setState({
            list: otb,
          });
          //  }
        }
      }
    }
  };

  setVisibleOfUiScript =(name ,value ,rcd)=>{
    let otb = this.state.list;
    const frecord = rcd;

    
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        console.log("145" ,frecord[i].name );
        
        frecord[i].uivalid.visible = value;
        let type = frecord[i].type;
        if (value === "false") {
          if (type === "filter" ||type === "filter_ref") {
            this.setFieldValueOfUiScript(name, {
              filter: [
                {
                  co: "",
                  cl: "",
                  mc: "",
                  an: "",
                  ct: "",
                  af: "",
                  rf: { id: "", value: "" },
                  dc: { id: "", value: "" },
                  ch: [],
                },
              ],
              timeline: "",
            },"",rcd);
          } else if (type === "boolean") {
            this.setFieldValueOfUiScript(name, "false","",rcd);
          } else if (type === "multi_select") {
            this.setFieldValueOfUiScript(name, { record: [] },"",rcd);
          } else {
            this.setFieldValueOfUiScript(name, "","",rcd);
          }
        }
        // if (this._isMounted) {
        //   this.setState({ sr: frecord });
        // }
        if (frecord[i].name === name) {
      
          // if (this._isMounted) {
          this.setState({
            list: otb,
          });
          //  }
        }
      }
    }

  }

  setMandatoryOfUiScript = (name, value, rcd) => {
    let otb = this.state.list;
    let frecord = rcd;
    for (let i = 0; i < frecord.length; i++) {
      if (
        name === "id" ||
        name === "created" ||
        name === "created_by" ||
        name === "updated" ||
        name === "updated_by"
      ) {
        break;
      }
      if (frecord[i].name === name) {
        frecord[i].uivalid.mandatory = value;
        if (this._isMounted) {
          this.setState({
            list: otb,
          });
        }
      }
    }
  };

  setErrorFromApForm = (err, isError) => {
    this.setState({ script_err: err, page_script_err: isError });
  };


  selectedRows(checked, index) {
    let rcd = this.state.list[0].rcd;

    rcd[index].colr[0].ref = checked;
    // if (checked) {
    //   this.state.list[0].heading[0].ref = true;
    //   for (let i = 0; i < rcd.length; i++) {
    //     this.state.list[0].rcd[i].colr[0].ref = true;
    //   }
    //   this.setState({ list: this.state.list });
    // } else {
    //   this.state.list[0].heading[0].ref = false;
    //   for (let i = 0; i < rcd.length; i++) {
    //     this.state.list[0].rcd[i].colr[0].ref = false;
    //   }
    //   this.setState({ list: this.state.list });
    // }

    this.setState({ list: this.state.list });
  }

  selectedInfo = (index) => {
    let rcdId = this.state.list[0].rcd[index].colr[1].r_id;
    let tabName = this.state.listName;
    let rcdInfo = true;
    let obj = { rcdId: rcdId, tabName: tabName, rcdInfo: rcdInfo };
    this.setState({ listRcdInfo: obj, isListRcdInfo: true });
  };

  handleCloseListRcdInfo = () => {
    this.setState({
      isListRcdInfo: false,
      listRcdInfo: { rcdId: "0", tabName: "" },
    });
  };

  setModal(mod) {
    this.setState({ modal: mod });
  }

  selectedAllRows(checked) {
    let rcd = this.state.list[0].rcd;
    if (checked) {
      this.state.list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        this.state.list[0].rcd[i].colr[0].ref = true;
      }
      this.setState({ list: this.state.list });
    } else {
      this.state.list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        this.state.list[0].rcd[i].colr[0].ref = false;
      }
      this.setState({ list: this.state.list });
    }
  }

  selectedAction(st) {
    let arr = st.split(",");
    let btnNamee = arr[0];
    let url = arr[1];

    if (this.state.list[0].rcd.length > 0) {
      if (url === "/loom/delete/record") {
        this.setState({ btnNamee: "Delete", modal: true, url: url });
      } else {
        let btnNamee = arr[0];
        this.setState({ btnNamee: btnNamee, modal: true, url: url });
        // this.selectAction(url ,btnNamee);
      }
    }
  }

  selectAction(url) {
    this.setState({ isClick: true });
    let rcd = this.state.list[0].rcd;
    let postBody = [];
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    if (postBody.length > 0) {
      var token = localStorage.getItem("token");
      var userDetails = localStorage.getItem("userDetails");
      axios
        .post(this.state.loca + "/loom/selectedAction", jso, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            const record = resp.data;
            if ("Error" in record) {
              // toast(record.Error, {
              //   position: "top-center",
              //   theme: "colored",
              //   type: "error",
              //   style: {
              //     marginBottom: userDetails.OS !== "null" ? 12 : 0,
              //   },
              // });
              // this.state.page_error === true && (
              //   <div
              //     className=" pageMessage alert alert-danger "
              //     //  form_alt
              //     role="alert"
              //   >
              //     {this.state.error}
              this.setState({page_error:true ,error:record.Error})
            } else {
              if ("message" in record) {
                // toast(record.message, {
                //   position: "top-center",
                //   theme: "colored",
                //   type: "success",
                //   style: {
                //     marginBottom: userDetails.OS !== "null" ? 12 : 0,
                //   },
                // });
                this.setState({page_message:true ,message:record.message})
              } else {
                toast(record, {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              }
              // this.refresh();
              this.externalRecord();
            }
          },
          (error) => {
            this.setState({ loading: false, modal: false, isClick: false });
            toast("Something went wrong", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        )
        .finally(() => {
          this.setState({ loading: false, modal: false, isClick: false });
        });
    } else {
      toast("Select any record", {
        position: "top-center",
        theme: "colored",
        type: "error",
      });
      this.setState({ loading: false, modal: false, isClick: false });
    }
  }

  dismiss() {
    this.props.unmountMe();
  }

  checkIfTypeNotExists = (type) => {
    for (let i = 0; i < this.listOfTypes.length; i++) {
      if (this.listOfTypes[i] === type) {
        return false;
      }
    }
    return true;
  };

  filterItem() {
    this.setState({ showlist: !this.state.showlist });
    this.setState({ showbtn: !this.state.showbtn });
    // if (this.state.showlist === true) {
    //   this.setState({ isTop: "49.3px" })
    // } else {
    //   this.setState({ isTop: "" });
    // }
  }

  filterClear() {
    var fltarray = [];
    fltarray.push(this.state.filt);
    this.context.removeFilterByTableName(this.state.listName);
    this.setState({ filtarray: fltarray, noRecord: false });
    var tm = "";
    this.setState({ timeline: tm });
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
    pp += ',{"timeLine":"' + tm + '"}]}';
    this.setList(pp);
    if (this.state.showlist && this.state.isMobile) {
    //  this.setState({ showlist: false });
      this.handleCloseFilterItem()
    }
  }

  filtersubmit() {
    let srt = this.state.sortColumn;
    srt.sort = true;
    this.setState({ sortColumn: srt });
    this.alreadyRun.current = true;
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","value":"loom"}}';
    fs +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    fs += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    fs += ',{"timeLine":"' + this.state.timeline + '"}]}';
    let len = this.state.filtarray.length;
    if (this.state.filtarray[len - 1].co !== "") {
      if (
        this.state.filtarray[len - 1].an !== "" ||
        this.state.filtarray[len - 1].rf.value !== "" ||
        this.state.filtarray[len - 1].dc.value !== ""
      ) {
        this.setList(fs);
      }
    } else {
      this.setList(fs);
    }
    if (this.state.showlist && this.state.isMobile) {
    //  this.setState({ showlist: false });
    this.handleCloseFilterItem()
    }
  }

  callbtn(e, nam) {
    var btn = this.state.button;
    var mnrecord = this.state.mainrecord;
    var btntype = "";
    var calltype = "";
    var foundbtn = false;


    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].name === nam) {
        btntype = btn[ij].returnWebLocation;
        calltype = btn[ij].call_type;
        foundbtn = true;
        break;
      }
    }

    if (foundbtn === true && btntype !== "" && calltype !== "") {
      this.setState({
        page_error: false,
        error: "",
        loading: true,
        page_message: false,
        message: "",
      });
      if (calltype === "module") {
        this.callform(e, "new", this.state.listName, 0);
      } else {
        // if (nam === "update") {
        //   this.updateRecord({ name: "update" });
        // } else {
        mnrecord.formRecord[2].record = this.state.record;
        var token = localStorage.getItem("token");
        axios
          .post(this.state.loca + btn[ij].webUrl, mnrecord, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const rcd = resp.data;
            if (rcd !== "") {
              if ("Error" in rcd) {
                this.setState({
                  loading: false,
                  page_error: true,
                  error: rcd.Error,
                });
              } else {
                this.setState({ loading: false });
                var msg = rcd.formRecord[4].message;
                if (msg !== "") {
                  this.setState({ page_message: true, message: msg });
                }
                if (btntype === "NextPage") {
                  // var nextP = btn[ij].nextPage;
                  var nextP = rcd.formRecord[3].button.nextPage;
                  if (nextP === "List") {
                    this.callNextPage();
                  }
                }
              }
            }
          });
       // }
      }

      // } else {
      // 	this.setState({
      // 		page_error: true,
      // 		error: error_String,
      // 	});
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      // 	// this.props.unmountMe();
      // }
    }
  }

  async callfilter(filtarray, in_index, col_id) {
    var rcd = this.state.list;
    if (col_id !== -1) {
      for (let i = 0; i < rcd.length; i++) {
        if (
          filtarray[in_index].ct === "choice" &&
          filtarray[in_index].ch.length === 0
        ) {
          let chc = await this.getChoiceRcd(col_id).then((res) => {
            if (res.length > 0) {
              filtarray[in_index].ch = res;
              filtarray[in_index].an = res[0].name;
            } else {
              filtarray[in_index].ch = [];
            }
          });
        }
      }
    }
    this.setState({
      filtarray: filtarray,
    });
  }

  async getChoiceRcd(col_id) {
    var token = localStorage.getItem("token");
    let ck = await axios
      .get(this.state.loca + "/loom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          if ("Error" in res) {
            this.setState({
              loading: false,
              page_error: true,
              error: res.Error,
            });
          } else {
            let chk = res.choiceRecords;
            chk.unshift({ name: "none", value: "None" });
            return chk;
          }
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          this.props.showErrorCompo({ state: { err: err } });
        }
      );
    return ck;
  }

  calltimeline(timeline) {
    this.setState({
      timeline: timeline,
    });
  }

  changeSearch(val) {
    if (val === "") {
      this.setState({ srch: "" });
      this.filterClear();
    } else {
      this.setState({ srch: val });
    }
  }

  callSearchbtn() {
    var fld = this.state.srch_column;
    var val = this.state.srch;
    var col = this.state.columnList;
    var type = "";
    var label = "";
    for (var c = 0; c < col.length; c++) {
      if (fld === col[c].name) {
        type = col[c].type;
        label = col[c].label;
      }
    }
    if (val.length > 1) {
      let filter = [
        {
          ct: type,
          af: "",
          mc: "contains",
          cl: label,
          co: fld,
          an: val,
          rf: { id: "", value: "" },
        },
      ];
      var fs = '{"formRecordList":[';
      fs += '{"application":{"id":"","value":"loom"}}';
      fs +=
        ',{"table":{"id":"","value":"' +
        this.state.listName +
        '","label":"' +
        this.state.tablabel +
        '"}}';
      fs += ',{"records":[]}';
      fs +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"1","page_records":"0"}}';
      fs += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
      fs += ',{"filter":' + JSON.stringify(filter) + "}";
      fs += ',{"timeLine":""}]}';
      this.setList(fs);
    }
  }

  searchColumn(e) {
    this.setState({ srch_column: e.target.value });
  }

  setContext(val, x, y) {
    console.log("err" ,val);
    
    if (val === 2) {
      this.menuX.current = x;
      this.menuY.current = y;
      this.setState({ showContext: true });
    } else {
      this.setState({ showContext: false });
    }
  }

  getListRecord(listName, srrt, flt) {
    console.log("kkkkkkkkkkkkkkkkk", this.filt1.current);
    
    this.setState({ listName: listName });
    //  let pg = localStorage.getItem("pageClicked");
    let pg = this.context.getFilterByTableName(listName);

    // if(!pg){
    //    pg = {
    //     page: this.state.page_clicked,
    //     filter: this.state.filtarray,
    //     sort: this.state.sortColumn,
    //     timeline: this.state.timeline,
    //   };
    // }
    this.state.sortColumn.name = srrt.column;
    this.state.sortColumn.sort = srrt.asc === "true" ? true : false;
    let p = 1;
    let srt = srrt.asc;
    let srtPage = srrt.column;
    var filterstate = "";
    var filter = this.state.filter;

    console.log("filter: ", filter);
    
    var tm = "";
    if (filter !== "null" && filter !== null) {
      if (!this.filt1.current) 
      this.filt1.current = this.state.filter.filter;
      tm = this.state.timeline;
    } else {
      if (!this.filt1.current) 
      this.filt1.current = flt.filter;
      tm = flt.timeline;
    }
    if (pg) {
      let pag = pg;
      p = pag.page;
      filterstate = pag.filter;
      tm = pag.timeline;
      let sort = pag.sort;
      srt = sort.sort;
      if (sort.name !== "") {
        srtPage = sort.name;
      }
      this.setState({ filtarray: filterstate, sortColumn: sort, timeline: tm });
      localStorage.removeItem("pageClicked");
    }
    console.log("filtt1",this.filt1.current);
    console.log("fil_state",filterstate);

    var farray = [];
    if (filterstate === "") {
      if (
        this.filt1.current === "" ||
        this.filt1.current === "undefined" ||
        this.filt1.current === undefined ||
        this.filt1.current === "null" ||
        this.filt1.current === null ||
        !this.filt1.current
      ) {
        farray.push(this.state.filt);
        this.state.sortColumn.name = "";
        this.state.sortColumn.sort = true;
        filterstate = farray;
      } else {
        // if (!this.filt1.current) 
        farray = this.filt1.current;
        filterstate = farray;
      }
    }

    this.setState({
      filter: filterstate,
      timeline: tm,
    });
    
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","value":"loom"}}';
    tablere +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      p +
      '","page_records":"0"}}';
    tablere +=
      ',{"sort":{"asc":"' +
      srt +
      '","column":"' +
      srtPage +
      '","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + tm + '"}]}'; //flt.timeLine
    this.setList(tablere);
  }

  getSortAndFilter() {
    console.log("getSortAndFilter");
    
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/loom/get/sortfilter/" + this.state.listName, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let rsp = resp.data;
          this.getListRecord(
            this.state.listName,
            rsp.value[0].sort,
            rsp.value[1]
          );
        },
        (error) => {}
      );

    axios
      .get(this.state.loca + "/loom/get/listcontrols/" + this.state.listName, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let rsp = resp.data;
          for (let k = 0; k < rsp.listControlls.length; k++) {
            if (rsp.listControlls[k].controllName === "Filters") {
              // rsp.listControlls.unshift({filter:{filter:[],timeline:''}, filterId:`0`,filterName:'None'})
              var fltarray = [];
              fltarray.push(this.state.filt);
              rsp.listControlls[k].subMenu.unshift({
                filter: { filter: fltarray, timeline: "" },
                filterId: `0`,
                filterName: "None",
              });
            }
          }
          this.setState({ listControlls: rsp.listControlls });
          /* this.getListRecord(
            this.state.listName,
            rsp.value[0].sort,
            rsp.value[1]
          ); */
        },
        (error) => {}
      );
  }

  storeSrot(sort, pp) {
    var token = localStorage.getItem("token");
    let js = { tableName: this.state.listName, sort: sort };
    axios
      .post(this.state.loca + "/loom/set/sort", js, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          this.setList(pp);
        },
        (error) => {}
      );
  }

  saveFilter(filterId = "0") {
    var token = localStorage.getItem("token");
    let ft = { filter: this.state.filtarray, timeLine: this.state.timeline };
    let js = {
      filterName: this.state.filterName,
      filterId: filterId,
      tableName: this.state.listName,
      filter: ft,
    };
    if (
      this.state.timeline === "" &&
      this.state.filtarray[0].co === "" &&
      filterId === "0" /* ||
      (!this.state.filtarray[0].an && !this.state.filtarray[0].rf.id) */
    ) {
      toast.error("Select filter");
      return;
    } else {
      axios
        .post(this.state.loca + "/loom/save/filter", js, {
          headers: { Authorization: "Bearer " + token },
        })
        .then(
          (rsp) => {
            let resp = rsp.data;
            if (filterId === `0`) toast.success("Saved filter");
           // this.setState({filterName:""})
          },
          (error) => {}
        );
    }
  }

  getMultiSelectValues=(obj)=>{
    console.log(obj);
    let rcd=obj.record;
    let res="";
    if(rcd?.length!=0){
      for(let k=0; k<10 && k<rcd?.length;k++){
        if(k>0)
          res+=",";
         res=res+ rcd[k]?.value
      }
    }
    console.log(res);
    return res;
  }

  removeFilter() {
    var token = localStorage.getItem("token");
    let js = { tableName: this.state.listName };
//    this.setState({ showlist: false });
this.handleCloseFilterItem()
    axios
      .post(this.state.loca + "/loom/remove/filter", js, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          this.getSortAndFilter();
        },
        (error) => {}
      );
  }

  refresh() {
    this.getSortAndFilter();
    this.multipleSelectedRows.current=[];
    this.rowsScriptHasBeenRun.current=[];
  }

  back() {
    // navigate(-1);
  }

  menuFn(body) {
    // this.refresh();
    // this.back();
    // if (body !== "refresh()") {
    //   this.props.showViewCompo(this.state.listName);
    // }
    const { navigateWithSearchParams } = this.props;

    if (body === "back()") {
      this.back();
    } else if (body === "refresh()") {
      this.refresh();
    } else {
      navigateWithSearchParams(
        "/private/setup-view",
        {
          tableName: this.state.listName,
        },
        {}
      );
    }
  }

  selectFilterFromSubMenu = (fil, filterId) => {
    if (filterId === `0`) {
      this.filterClear();
    }
    let filtarray = fil.filter;
    let timeline = fil.timeline;

    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","value":"loom"}}';
    fs +=
      ',{"table":{"id":"","value":"' +
      this.state.listName +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    let len = filtarray.length;
    this.setList(fs);
    var token = localStorage.getItem("token");
    let ft = { filter: filtarray, timeLine: timeline };
    let js = {
      filterName: this.state.filterName,
      filterId: filterId,
      tableName: this.state.listName,
      filter: ft,
    };

    axios
      .post(this.state.loca + "/loom/submenu/filter", js, {
        headers: { Authorization: "Bearer " + token },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
        },
        (error) => {}
      );
    let listcon = this.state.listControlls;
    for (let k = 0; k < listcon.length; k++) {
      if (listcon[k].controllName === "Filters") {
        // rsp.listControlls.unshift({filter:{filter:[],timeline:''}, filterId:`0`,filterName:'None'})
        //  var fltarray = [];
        //fltarray.push(this.state.filt);
        //rsp.listControlls[k].subMenu.unshift({filter:{filter:fltarray,timeline:''}, filterId:`0`,filterName:'None'})
        let subMenu = listcon[k].subMenu;
        for (let j = 0; j < subMenu.length; j++) {
          let ott = subMenu[j];
          if (ott.filterId == filterId) {
            ott.selected = true;
          } else {
            ott.selected = false;
          }
        }
      }
    }

    this.setState({ listControlls: listcon });

    if (this.state.isMobile) {
      this.setState({ activeMenu: null });
      this.setState({ isMenuMob: false });
    }
  };

  clickOnMenu = (e) => {
    const { isMenu, isMenuMob } = this.state;
    this.setState({ isMenu: true });
    if (isMobile) {
      e.stopPropagation();
      if (isMenuMob === false) {
        this.setState({ isMenuMob: true });
      } else {
        this.setState({ isMenuMob: false });
      }
    }
  };

  subMenu = () => {
    this.setState({ subMenu: true });
  };
  handleMouseEnter = (menu) => {
    this.setState({ activeMenu: menu });
  };

  handleMouseLeave = () => {
    this.setState({ activeMenu: null });
    if (this.state.isMobile) {
      this.setState({ isMenuMob: false });
    }
  };

  handleClick = (controllName) => {
    if (this.state.isMobile) {
      this.setState({
        activeMenu:
          this.state.activeMenu === controllName ? null : controllName,
      });
    }
  };

  handleTouchStart = (controllName) => {
    this.setState((prevState) => ({
      openMenuItem:
        prevState.openMenuItem === controllName ? null : controllName,
    }));
  };

  handleTouchEnd = () => {
    // Remove this if you want the menu to stay open after touch
    // this.setState({ openMenuItem: null });
  };

  handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevents the click from bubbling up
  };



  render() {
    return (
      <div
        id="context_handler"
        className={`${list["pagesetup"]} reference-dropdown-hide-on-wheel`}
        style={{
          overflowX: "auto",
          overflowY: isMobile || this.state.showlist ? "hidden" : "",
        }}
        onClick={() => this.setState({ isMenuMob: false })}
      >
        {this.state.loading === true ? (
          <div className="g-compo-loader">
            <WorkInProgress
              tableName={this.state.listName}
              type={this.state.rty}
            ></WorkInProgress>
          </div>
        ) : (
          <div>
            {/* {this.state.page_error === true && (
              <div className="alert alert-danger form_alt" role="alert">
                {this.state.error}
              </div>
            )} */}
            {isMobile ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid" 
                              onTouchStart={(e) => {
                                setTimeout(() => {
                                  e.preventDefault();
                                  this.setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                                }, 100);
                              }}
                >
                  <span className="obj_head ">{this.state.tablabel}</span>
                </div>
                <div className="col-lg filter-ic text-center">
                  {/* {this.state.showlist === false && */}
                  {!this.state.isDashboardinfo && (
                    <select
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={this.state.srch_column}
                      onChange={(e) => this.searchColumn(e)}
                    >
                      {this.state.columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}
                  {/* {this.state.showlist === false && */}
                  {!this.state.isDashboardinfo && (
                    <input
                      className="srch-mob form-control"
                      type="search"
                      aria-label="Search"
                      value={this.state.srch}
                      
                      onChange={(e) => this.changeSearch(e.target.value)}
                    ></input>
                  )}
                  {/* {this.state.showlist === false && */}
                  {!this.state.isDashboardinfo && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      
                      onClick={() => this.callSearchbtn()}
                    ></input>
                  )}
                </div>

                {!this.state.isDashboardinfo && (
                  <div
                    className="col-lg martop10 filter-ic d-flex "
                    style={{ justifyContent: "center" }}
                  >
                    <div style={{ fontSize: "20px" }}>
                      <i
                        className="fa-solid fa-bars  vlpointer "
                        /*  */
                        onClick={this.clickOnMenu}
                        style={{ padding: "0px 4px" }}
                      ></i>
                      {!this.state.isDashboardinfo && this.state.isMenuMob && (
                        <ul
                          className="dropdown-menu"
                          style={{
                            display: "block",
                          }}
                          onClick={this.handleDropdownClick}
                        >
                          {!this.state.isDashboardinfo &&
                            this.state.isMenuMob && (
                              <div>
                                {this.state.listControlls.map((obj, index) => (
                                  <li
                                    key={index}
                                    onTouchStart={() =>
                                      this.handleTouchStart(obj.controllName)
                                    }
                                    onTouchEnd={this.handleTouchEnd}
                                  >
                                    <a
                                      className="dropdown-item"
                                      href="#"
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <span>{obj.controllName}</span>
                                      <span>{">"}</span>
                                    </a>
                                    {obj.subMenu.length !== 0 &&
                                      this.state.openMenuItem ===
                                        obj.controllName && (
                                        <ul
                                          className="submenu"
                                          style={{
                                            listStyleType: "none",
                                            paddingLeft: "0px",
                                          }}
                                        >
                                          {obj.subMenu.map((subobj, ind) => (
                                            <li
                                              className="submenu-item"
                                              key={ind}
                                              onTouchEnd={this.handleTouchEnd}
                                              onTouchStart={(e) => {
                                                this.selectFilterFromSubMenu(
                                                  subobj.filter,
                                                  subobj.filterId
                                                );
                                              }}
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "4px",
                                              }}
                                            >
                                              <span>{subobj.filterName}</span>
                                              {subobj.selected && (
                                                <img
                                                  src={trueImg}
                                                  height={15}
                                                  width={15}
                                                />
                                              )}
                                            </li>
                                          ))}
                                        </ul>
                                      )}
                                  </li>
                                ))}
                              </div>
                            )}
                        </ul>
                      )}

                      <i
                        className="fa fa-filter vlpointer pdtop5 mx-1"
                        
                        onClick={this.filterItem}
                      ></i>
                      <i
                        className="fa fa-cog vlpointer cog_pd pdtop5"
                        
                        onClick={this.handleShow}
                      ></i>
                    </div>
                    <div className="text-end centre-flex">
                      <span
                      //  style={{ marginRight: "0.5em" }}
                      >
                        <input
                          className=" mybt"
                          type="button"
                          value="<<"
                          onClick={this.firstPage}
                          disabled={this.leftReadOnly()}
                        />
                        <input
                          className=" mybt"
                          type="button"
                          value="<"
                          onClick={this.previousPage}
                          disabled={this.leftReadOnly()}
                        />
                      </span>
                      <span className="form-control in-put">
                        {this.state.page_records}
                      </span>
                      {/* <span>
                      <input
                        className="in-put"
                        type="text"
                        value={this.state.page_records}
                        readOnly={true}
                      ></input>
                    </span> */}
                      <span className="text-uppercase spfont"> of </span>
                      <span className="rcd_count spfont">
                        {this.state.record_count}
                      </span>
                      <span>
                        <input
                          className=" mybt"
                          type="button"
                          value=">"
                          onClick={this.nextPage}
                          disabled={this.rightReadOnly()}
                        />
                      </span>
                      <span>
                        <input
                          className=" mybt"
                          type="button"
                          value=">>"
                          disabled={this.rightReadOnly()}
                          onClick={this.lastPage}
                        />
                      </span>
                    </div>
                  </div>
                )}
                <div className="col-lg mt-0 mt-md-2 text-end me-2">
                  {this.state.button?.length !== 0 && (
                    <select
                      ref={this.myRef}
                      defaultValue="None"
                      style={{ width: 120, borderRadius: "0.25rem" }}
                      onChange={(e) => {
                        e.target.value !== "none" &&
                          this.selectedAction(e.target.value);
                      }}
                    >
                      <option value="none">None</option>
                      {!this.state.isDashboardinfo &&
                        this.state.button.length > 0 &&
                        this.state.button.map(
                          (obj, oo_i) =>
                            obj.ui_type === "selectedAction" && (
                              <option
                                key={oo_i}
                                value={`${obj.value},${obj.webUrl}`}
                              >
                                {obj.value}
                              </option>
                            )
                        )}
                    </select>
                  )}
                  {!this.state.isDashboardinfo &&
                    this.state.button.map(
                      (obj, oo_i) =>
                        obj.ui_type === "formButton" && (
                          <input
                            type="button"
                            className="csm_btn csm_btn_pri col-md-2 sub-btn"
                            key={oo_i}
                            // onClick={(e) => this.callbtn(obj.name)}
                            onMouseDown={(e) => this.callbtn(e, obj.name)}
                            value={obj.value}
                          ></input>
                        )
                    )}
                  {/* <input
                    type="button"
                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                    // onClick={(e) => this.callbtn(obj.name)}
                    onMouseDown={(e) => this.callbtn(e, "update")}
                    value={"Update"}
                  ></input> */}
                </div>
                <Modal
                  show={this.state.showlist && this.state.isMobile}
                  onHide={this.handleCloseFilterItem}
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="mod-ht">
                    <div>
                      {!this.state.isDashboardinfo && (
                        <div>
                          <div className="mb-2 row g-2">
                            <div className="col-12 col-md-5">
                              <input
                                type="text"
                                placeholder="Filter Name"
                                className="form-control form-control-sm"
                                value={this.state.filterName}
                                onChange={(e) => {
                                  this.setState({ filterName: e.target.value });
                                }}
                              />
                            </div>
                            <div className="col-12 col-md-7 d-flex justify-content-between mt-2 mt-md-0">
                              <Button
                                variant="primary"
                                size="sm"
                                className="w-50 me-2"
                                disabled={this.state.filterName === ""}
                                onClick={() => this.saveFilter()}
                              >
                                Save Filter
                              </Button>
                              <Button
                                variant="primary"
                                size="sm"
                                className="w-50"
                                onClick={this.removeFilter}
                              >
                                Remove Filter
                              </Button>
                            </div>
                          </div>
                          <NewFilterCompo
                            showlist={this.state.showlist}
                            col_mn={this.state.col_mn}
                            col_depend={this.state.column_depend}
                            call_fil={(filtarray, in_index, col_id) =>
                              this.callfilter(filtarray, in_index, col_id)
                            }
                            filtarray={this.state.filtarray}
                            timeline={this.state.timeline}
                            call_tm={(timeline) => this.calltimeline(timeline)}
                            loca={this.state.loca}
                            isMobile={this.state.isMobile}
                            isReference={true}
                          ></NewFilterCompo>
                        </div>
                      )}
                      <div className="mt-4 d-flex justify-content-end">
                        <Button
                          variant="primary"
                          className="mx-2"
                          disabled={
                            this.state.timeline === "" &&
                            this.state.filtarray[0].an === "" &&
                            this.state.filtarray[0].rf.value === ""
                          }
                          onClick={this.filtersubmit}
                        >
                          Run
                        </Button>
                        <Button
                          variant="primary"
                          className="mx-2"
                          onClick={this.filterClear}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            ) : (
              <>
                <div
                  className={`row ${list.bck}`}
                  /*  style={{ position: "fixed", width: "74.1%", zIndex: "2" }} */
                  onContextMenu={(e) => {
                    e.preventDefault();
                    this.setContext(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }}
                >
                  {!this.state.isDashboardinfo && (
                    <div
                      className=""
                      // onWheel={}
                    >
                      {!this.state.isDashboardinfo &&
                        this.state.showContext && (
                          <ul
                            className="dropdown-menu"
                            style={{
                              display: "block",
                              top: this.menuY.current,
                              left: this.menuX.current,
                              padding: "0px",
                              fontSize: "14px",
                              borderRadius: "0px",
                            }}
                          >
                            {!this.state.isDashboardinfo &&
                              this.state.contextMenu &&
                              this.state.contextMenu.map((obj, ind) => (
                                <div key={ind}>
                                  {obj.name === "refresh" && (
                                    <li
                                      onMouseDown={() => {
                                        this.menuFn(obj.script);
                                      }}
                                    >
                                      <a
                                        className="dropdown-item"
                                        style={{
                                          borderBottom:
                                            ind !==
                                              this.state.contextMenu.length -
                                                1 && "1px solid #e3e3e3",
                                          fontWeight: "500",
                                        }}
                                        href="#"
                                      >
                                        {obj.Label}
                                      </a>
                                    </li>
                                  )}
                                </div>
                              ))}
                          </ul>
                        )}
                      
                      {!this.state.isDashboardinfo &&
                        this.state.openInNewTab && (
                          <ul
                            className="dropdown-menu"
                            style={{
                              display: "block",
                              top: this.menuY.current,
                              left: this.menuX.current,
                              padding: "0px",
                              fontSize: "14px",
                              borderRadius: "0px",
                            }}
                          >
                            {!this.state.isDashboardinfo &&
                            this.state.openInNewTab &&
                           ( 
                           
                      <>
                         <li
                            onMouseDown={() => {
                              this.openInNewTabFn(); 
                            }}
                          >
                            <a
                              className="dropdown-item"
                              style={{
                                // borderBottom:
                                //   ind !==
                                //     this.state.contextMenu.length -
                                //       1 && "1px solid #e3e3e3",

                                fontWeight: "500",
                              }}
                              href="#"
                            >
                              {"Open in new tab"}
                            </a>
                          </li>
                          <li
                            onMouseDown={() => {
                            /*   this.openInNewTabFn();  */
                                this.getShowMatchingList()
                            }}
                          >
                            <a
                              className="dropdown-item"
                              style={{
            

                                fontWeight: "500",
                              }}
                              href="#"
                            >
                              {"Show matching"}
                            </a>
                          </li>
                      
                      </>
                        
                        )}
                          </ul>
                        )}
                    </div>
                  )}
                  <div
                    className="d-flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    {!this.state.isDashboardinfo && (
                      <div>
                        <i /* className="fa-solid fa-bars vlpointer" */
                          className="fa-solid fa-bars  vlpointer "
                          /*  */
                          onClick={this.clickOnMenu}
                          style={{ padding: "0px 4px" }}
                        ></i>
                        {!this.state.isDashboardinfo && this.state.isMenu && (
                          <ul
                            className="dropdown-menu"
                            style={{
                              display: "block",
                              /*    top: this.menuY.current,
                            left: this.menuX.current, */
                            }}
                          >
                            {!this.state.isDashboardinfo &&
                              this.state.isMenu && (
                                <div>
                                  {this.state.listControlls.map(
                                    (obj, index) => (
                                      <li
                                        key={index}
                                        onMouseEnter={() =>
                                          this.handleMouseEnter(
                                            obj.controllName
                                          )
                                        }
                                        onMouseLeave={this.handleMouseLeave}
                                      >
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <span>{obj.controllName}</span>
                                          <span>{">"}</span>
                                        </a>
                                        {obj.subMenu?.length !== 0 &&
                                          this.state.activeMenu ===
                                            obj.controllName && (
                                            <ul
                                              className="submenu"
                                              style={{
                                                listStyleType: "none",
                                                paddingLeft: "0px",
                                              }}
                                            >
                                              {obj.subMenu.map(
                                                (subobj, ind) => (
                                                  <li
                                                    className="submenu-item"
                                                    key={ind}
                                                    onMouseDown={(e) => {
                                                      this.selectFilterFromSubMenu(
                                                        subobj.filter,
                                                        subobj.filterId
                                                      );
                                                    }}
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "4px",
                                                    }}
                                                  >
                                                    <span>
                                                      {subobj.filterName}
                                                    </span>
                                                    {/* {subobj.selected && (<span>true</span>)} */}
                                                    {subobj.selected && (
                                                      <img
                                                        src={trueImg}
                                                        height={15}
                                                        width={15}
                                                      />
                                                    )}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                      </li>
                                    )
                                  )}
                                </div>
                              )}
                          </ul>
                        )}
                        <i
                          className="fa fa-filter vlpointer"
                          
                          onClick={this.filterItem}
                        ></i>

                        <i
                          className="fa fa-cog vlpointer px-1"
                          
                          onClick={this.handleShow}
                        ></i>

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === true && (
                            <input
                              className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              type="button"
                              value="Run"
                              // 
                              onClick={this.filtersubmit}
                            ></input>
                          )}

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === true && (
                            <input
                              className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              type="button"
                              value="Clear"
                              // 
                              onClick={this.filterClear}
                            ></input>
                          )}
                        {!this.state.isDashboardinfo &&
                          this.state.showlist === false && (
                            <select
                              className={`form-select ${list.namelist}`}
                              aria-label="Default"
                              value={this.state.srch_column}
                              onChange={(e) => this.searchColumn(e)}
                            >
                              {this.state.columnList.map((obj2, index) => (
                                <option key={index} value={obj2.name}>
                                  {obj2.label}
                                </option>
                              ))}
                            </select>
                          )}

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === false && (
                            <input
                              className="srch form-control"
                              type="search"
                              aria-label="Search"
                              value={this.state.srch}
                              
                              onChange={(e) =>
                                this.changeSearch(e.target.value)
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  this.callSearchbtn();
                                }
                              }}
                            ></input>
                          )}

                        {!this.state.isDashboardinfo &&
                          this.state.showlist === false && (
                            <input
                              className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              type="button"
                              value="Search"
                              
                              onClick={() => this.callSearchbtn()}
                            ></input>
                          )}
                      </div>
                    )}
                    <div
                      className={
                        this.state.isDashboardinfo === true
                          ? "col-md-12 l-box-mar-pad"
                          : ""
                      }
                    >
                      <span
                        className={
                          this.state.isDashboardinfo === true
                            ? "fw-bold text-center list-font-sz"
                            : "obj_head"
                        }
                      
                      >
                        {this.state.tablabel}
                      </span>
                    </div>

                    {!this.state.isDashboardinfo && (
                      <div>
                        <div className="tpi">
                          {this.state.button?.length !== 0 && (
                            <select
                              ref={this.myRef}
                              defaultValue="None"
                              style={{ width: 120, borderRadius: "0.25rem" }}
                              onChange={(e) => {
                                e.target.value !== "none" &&
                                  this.selectedAction(e.target.value);
                              }}
                            >
                              <option value="none">None</option>
                              {!this.state.isDashboardinfo &&
                                this.state.button?.length > 0 &&
                                this.state.button.map(
                                  (obj, oo_i) =>
                                    obj.ui_type === "selectedAction" && (
                                      <option
                                        key={oo_i}
                                        value={`${obj.value},${obj.webUrl}`}
                                      >
                                        {obj.value}
                                      </option>
                                    )
                                )}
                            </select>
                          )}
                          {!this.state.isDashboardinfo &&
                            this.state.button.map(
                              (obj, oo_i) =>
                                obj.ui_type === "formButton" && (
                                  <input
                                    type="button"
                                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                                    key={oo_i}
                                    // onClick={(e) => this.callbtn(obj.name)}
                                    onMouseDown={(e) =>
                                      this.callbtn(e, obj.name)
                                    }
                                    value={obj.value}
                                  ></input>
                                )
                            )}

                          {/* <input
                            type="button"
                            className="csm_btn csm_btn_pri col-md-2 sub-btn"
                            // onClick={(e) => this.callbtn(obj.name)}
                            onMouseDown={(e) => this.callbtn(e, "update")}
                            value={"Update"}
                          ></input> */}

                          <span style={{ marginLeft: "0.5em" }}>
                            <input
                              className=" mybt"
                              type="button"
                              value="<<"
                              onClick={this.firstPage}
                              disabled={this.leftReadOnly()}
                            />
                          </span>
                          <span>
                            <input
                              className=" mybt"
                              type="button"
                              value="<"
                              onClick={this.previousPage}
                              disabled={this.leftReadOnly()}
                            />
                          </span>
                          <span className="form-control in-put">
                            {this.state.page_records}
                          </span>
                          <span className="text-uppercase spfont"> of </span>
                          <span className="rcd_count spfont">
                            {this.state.record_count}
                          </span>
                          <span>
                            <input
                              className=" mybt"
                              type="button"
                              value=">"
                              onClick={this.nextPage}
                              disabled={this.rightReadOnly()}
                            />
                          </span>
                          <span>
                            <input
                              className=" mybt"
                              type="button"
                              value=">>"
                              disabled={this.rightReadOnly()}
                              onClick={this.lastPage}
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {!this.state.isDashboardinfo &&
              this.state.showlist === false &&
              this.state.filString !== "" && (
                <div style={{ marginLeft: "0.5rem" }}>
                  {this.state.filString}
                </div>
              )}
            {/* <div> */}
            {!this.state.isDashboardinfo &&
              !this.state.isMobile &&
              this.state.showlist === true && (
                <div>
                  <div className="d-flex mb-2">
                    <input
                      type="text"
                      placeholder="Filter Name"
                      className="m-0 py-1 ms-2 mt-2 border-2"
                      value={this.state.filterName}
                      onChange={(e) => {
                        this.setState({ filterName: e.target.value });
                      }}
                    />
                    <input
                      className=" btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                      type="button"
                      value="Save Filter"
                      // disabled={rightReadOnly()}
                      disabled={this.state.filterName === "" ? true : false}
                      onClick={() => {
                        this.saveFilter();
                      }}
                    />

                    <input
                      className="btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                      type="button"
                      value="Remove Filter"
                      // disabled={rightReadOnly()}
                      onClick={this.removeFilter}
                    />
                  </div>
                  <NewFilterCompo
                    showlist={this.state.showlist}
                    col_mn={this.state.col_mn}
                    col_depend={this.state.column_depend}
                    call_fil={(filtarray, in_index, col_id) =>
                      this.callfilter(filtarray, in_index, col_id)
                    }
                    filtarray={this.state.filtarray}
                    timeline={this.state.timeline}
                    call_tm={(timeline) => this.calltimeline(timeline)}
                    loca={this.state.loca}
                    isMobile={this.state.isMobile}
                    isReference={true}
                  ></NewFilterCompo>
                </div>
              )}
            {this.state.list?.length === 0 && <div>No Record Found</div>}
            {this.state.list.map((lstobj, lst_i) => (
              <div
                className="heading_top table_set overflow-auto"
                key={lst_i}
                style={{
                  height: isMobile
                    ? this.context.formHeight - 91
                    : this.state.showlist
                    ? this.context.formHeight - 144.6
                    : this.context.formHeight - 10.6,
                  position: "relative",
                }}
              >
                {this.state.page_error === true && (
                  <div
                    className=" pageMessage alert alert-danger "
                    //  form_alt
                    role="alert"
                  >
                    {this.state.error}
                    <button
                      onClick={() => this.setState({ page_error: false })}
                      className="cancel-icon"
                      aria-label="Close alert"
                    >
                      &times; {/* This represents the 'X' icon */}
                    </button>
                  </div>
                )}
                {this.state.page_message === true && (
                    <div
                      className="pageMessage alert alert-success"
     
                      role="alert"
                    >
                      {this.state.message}
                      <button
                        onClick={() => this.setState({ page_message: false })}
                        className="cancel-icon"
                        aria-label="Close alert"
                      >
                        &times;
                      </button>
                    </div>
                  )}
                {this.state.page_script_err === true && (
                  <div
                    className="pageMessage alert alert-info"
                    role="alert"
                    style={{
                      backgroundColor: "#d2eef9",
                      borderColor: "#8ed5f0",
                    }}
                  >
                    {this.state.script_err}
                    <button
                      onClick={() => this.setState({ page_script_err: false })}
                      className="cancel-icon"
                      aria-label="Close alert"
                    >
                      &times;
                    </button>
                  </div>
                )}
                {/* over */}
                <table className="table table-bordered table-striped table-hover p-1">
                  <thead
                    // className={
                    //   this.props.isOpen === true
                    //     ? "sticky-header"
                    //     : "sticky-position"
                    // }
                    style={{
                      position: "sticky",
                      top: "0",
                    }}
                  >
                    <tr className="obj_name">
                      {lstobj.heading.map((obj, obj_i) =>
                        !this.state.isDashboardinfo ? (
                          <th key={obj_i} className="vlpointer">
                            {obj_i === 0 ? (
                              <span>
                                <input
                                  type="checkbox"
                                  className="checkpadd"
                                  checked={obj.ref}
                                  onChange={(e) => {
                                    this.selectedAllRows(e.target.checked);
                                  }}
                                ></input>
                                {!this.state.isMobile && (
                                  <span className={"btnsrcLst vlpointer"}>
                                    <i
                                      className="fa-solid fa-circle-info lstInfo"
                                      
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop"
                                    ></i>
                                  </span>
                                )}
                              </span>
                            ) : (
                              <div
                                onClick={(e) => this.showupdownbtn(obj.name)}
                              >
                                {obj.label}
                                {this.state.sortColumn.name === obj.name &&
                                  this.state.sortColumn.sort === true && (
                                    <i className=" icpadding fa fa-arrow-up"></i>
                                  )}
                                {this.state.sortColumn.name === obj.name &&
                                  this.state.sortColumn.sort === false && (
                                    <i className=" icpadding fa fa-arrow-down"></i>
                                  )}
                              </div>
                            )}
                          </th>
                        ) : (
                          <th key={obj_i} className="vlpointer">
                            <div>
                              {obj.label}
                              {this.state.sortColumn.name === obj.name &&
                                this.state.sortColumn.sort === true && (
                                  <i className=" icpadding fa fa-arrow-up"></i>
                                )}
                              {this.state.sortColumn.name === obj.name &&
                                this.state.sortColumn.sort === false && (
                                  <i className=" icpadding fa fa-arrow-down"></i>
                                )}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>

                  <tbody className="tbody bd_z_index_prop">
                    {lstobj.rcd.map((objj, objj_i) => (
                      <tr
                        className={
                          // this.state.multipleSelectedRows.includes(objj_i)
                          //   ? "selected obj_value"
                          //   : 
                            "obj_value"
                        }
                        key={objj_i}
                        //  onMouseDown={
                        //   (e) => this.handleRowClick(e, objj_i)
                        // }
                      >
                        {objj.colr.map((objr, objr_i) =>
                          !this.state.isDashboardinfo ? (
                            objr.isInPref && (
                              <td
                                key={objr_i}
                                className={
                                  objr.firstrecord === true
                                    ? "val_pad val_under vlpointer"
                                    :     this.isColSelected(objj_i ,objr_i)
                                    ? "selected val_pad"
                                    : "val_pad"
                                }
                                // className={
                                //   this.state.multipleSelectedRows.includes(objj_i)
                                //     ? "selected obj_value"
                                //     : "obj_value"
                                // }
                                onDoubleClick={() =>
                                  this.handleDoubleClickOpenForUpdate(
                                    objj_i,
                                    objr_i
                                  )
                                }
                                onClick={(e)=>{
                               this.handleRowClick(e, objj_i ,objr_i)
                                }}
                                onMouseDown={
                                  objr.firstrecord === true
                                    ? (e) =>
                                        this.callform(
                                          e,
                                          "first",
                                          objr.table,
                                          objr.r_id
                                        )
                                    : (e) => {
                                     this.handleMouseDown(objj_i, objr_i, e)
                                    }
                                }

                                onMouseUp={this.handleMouseUp}
                                onMouseOver={() => this.handleMouseOver(objj_i, objr_i)}
                                onContextMenu={(e) => {
                                  e.preventDefault();
                                  this.setListContext(
                                    e.button,
                                    e.nativeEvent.pageX,
                                    e.nativeEvent.pageY,
                                    { rid:objr.r_id ,row:objj_i ,col:objr_i}
                                  );
                                }}
                              >
                                {objr_i === 0 ? (
                                  <span  onClick={(e)=>{e.stopPropagation()}} >
                                    <input
                                      type="checkbox"
                                      className="checkpadd"
                                      checked={objr.ref}
                                      onChange={(e) => {
                                        this.selectedRows(
                                          e.target.checked,
                                          objj_i
                                        );
                                      }}
                                    ></input>

                                    {!this.state.isMobile && (
                                      <span className={"btnsrcLst vlpointer"}>
                                        <i
                                          className="fa-solid fa-circle-info lstInfo"
                                          
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                          onClick={() => {
                                            this.selectedInfo(objj_i);
                                          }}
                                        ></i>
                                      </span>
                                    )}
                                  </span>
                                ) : (
                                  <span >
                                    {/* {" "}
                          {objr.type === "filter" ||
                          objr.type === "filter_ref" ||
                          objr.name === "json" ? (
                            JSON.stringify(
                              objr.value?.length > 50
                                ? objr.value.slice(0, 50) + " ..."
                                : objr.value
                            )
                          ) : objr.type === "group_key_value" ? (
                            JSON.stringify(
                              objr.value.properties?.length > 50
                                ? objr.value.properties.slice(0, 50) +
                                    " ..."
                                : objr.value.properties
                            )
                          ) : objr.type === "html" ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  objr.value?.length > 50
                                    ? objr.value.slice(0, 50) + " ..."
                                    : objr.value,
                              }}
                            ></span>
                          ) : objr.value?.length > 50 ? (
                            objr.value.slice(0, 50) + " ..."
                          ) : (
                            objr.value
                          )} */}

                                    {(objr.type === "filter" ||
                                      objr.type === "filter_ref" ||
                                      objr.name === "json") &&
                                      // JSON.stringify(
                                      //   objr.value?.length > 50
                                      //     ? objr.value.slice(0, 50) + " ..."
                                      //     : objr.value
                                      // )
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                               onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                             
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                          JSON.stringify( objr.value)?.length > 50
                                            ?  JSON.stringify( objr.value).slice(0, 50) + " ..."
                                            :  JSON.stringify( objr.value)
                                        
                                      ))}
                                    {objr.type === "group_key_value" &&
                                      // JSON.stringify(
                                      //   objr.value.properties?.length > 50
                                      //     ? objr.value.properties.slice(0, 50) +
                                      //         " ..."
                                      //     : objr.value.properties
                                      // )
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                    
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        JSON.stringify(
                                          objr.value.properties?.length > 50
                                            ? objr.value.properties.slice(
                                                0,
                                                50
                                              ) + " ..."
                                            : objr.value.properties
                                        )
                                      ))}

                                    {objr.type === "html" &&
                   
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                        
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              objr.value?.length > 50
                                                ? objr.value.slice(0, 50) +
                                                  " ..."
                                                : objr.value,
                                          }}
                                        ></span>
                                      ))}
                                
                                    {objr.type === "reference" &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            objr.uivalid.visible ===
                                              "false" && (
                                              <p className="inVisibleField">
                                                Security prevents writing to
                                                this field
                                              </p>
                                            )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : objr.value?.value?.length > 50 ? (
                                        objr.value?.value.slice(0, 50) + " ..."
                                      ) : (
                                        objr?.value?.value
                                      ))}

                                 {objr.type === "multi_select" &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}
                                   {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : this.getMultiSelectValues(objr.value).length > 50 ? (
                                        this.getMultiSelectValues(objr.value).slice(0, 50) + " ..."
                                      ) : (
                                        this.getMultiSelectValues(objr.value)
                                      ))}

                                    {this.checkIfTypeNotExists(objr.type) &&
                                      (objr.editingCellIndex === true ? (
                                        <div
                                          key={objr_i}
                                          style={{ display: "flex" }}
                                          className="multiUpdateParent"
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        >
                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            this.formInner(objr, {
                                              colIndex: objr_i,
                                              rowIndex: objj_i,
                                            })}

                                          {!this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) &&
                                            objr.uivalid.visible ===
                                              "false" && (
                                              <p className="inVisibleField">
                                                Security prevents writing to
                                                this field
                                              </p>
                                            )}
                                          {this.state.notAllowedForUpdate.includes(
                                            objr.type
                                          ) && (
                                            <p className="inVisibleField">
                                              Not allowed update from list
                                            </p>
                                          )}
                                          <button
                                            className="multiUpdateBtn1"
                                            onClick={() => {
                                              this.handleUpdateSave(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            {" "}
                                            <span style={{ color: "green" }}>
                                              <i className="fa-regular fa-circle-check"></i>
                                            </span>
                                          </button>
                                          <button
                                            className="multiUpdateBtn2"
                                            onClick={() => {
                                              this.handleUpdateCancel(
                                                objj_i,
                                                objr_i
                                              );
                                            }}
                                          >
                                            <span style={{ color: "red" }}>
                                              <i className="fa-regular fa-circle-xmark"></i>
                                            </span>
                                          </button>
                                        </div>
                                      ) : objr.value?.length > 50 ? (
                                        objr.value.slice(0, 50) + " ..."
                                      ) : (
                                        objr.value
                                      ))}
                                  </span>
                                )}
                              </td>
                            )
                          ) : (
                            /* jab dashboard info true hofa */
                            <td key={objr_i}>
                              <span>
                                {" "}
                                {objr.type === "filter" ||
                                objr.type === "filter_ref" ||
                                objr.name === "json" ? (
                                  JSON.stringify(
                                    objr.value?.length > 50
                                      ? objr.value.slice(0, 50) + " ..."
                                      : objr.value
                                  )
                                ) : objr.type === "group_key_value" ? (
                                  JSON.stringify(
                                    objr.value.properties?.length > 50
                                      ? objr.value.properties.slice(0, 50) +
                                          " ..."
                                      : objr.value.properties
                                  )
                                ) : objr.type === "html" ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        objr.value?.length > 50
                                          ? objr.value.slice(0, 50) + " ..."
                                          : objr.value,
                                    }}
                                  ></span>
                                ) : objr.value?.length > 50 ? (
                                  objr.value.slice(0, 50) + " ..."
                                ) : (
                                  objr.value
                                )}
                              </span>
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}

            {this.state.noRecord === true && (
              <div className="norcd">No Record Found</div>
            )}
            <Modal
              show={this.state.modal}
              onHide={() => this.setModal(!this.state.modal)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirm {this.state.btnNamee}</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.setModal(!this.state.modal)}
                  disabled={this.state.isClick}
                  style={{ backgroundColor: this.state.isClick ? "gray" : "" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="danger"
                  onClick={() => this.selectAction(this.state.url)}
                  disabled={this.state.isClick}
                  style={{ backgroundColor: this.state.isClick ? "gray" : "" }}
                >
                  {this.state.btnNamee}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}

        {this.state.isListRcdInfo && (
          <Form_component
            listRcdInfo={this.state.listRcdInfo}
            handleCloseListRcdInfo={this.handleCloseListRcdInfo}
            isListRcdInfo={this.state.isListRcdInfo}
            loca={this.state.loca}
          ></Form_component>
        )}
        <Modal
          dialogClassName="m-md-auto my-modal d-flex justify-content-center align-items-center "
          // dialogClassName="my-modal"
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PreferenceComponent
              pref="user"
              /*      setRef={(val) => this.setRef(val)} */
              columnarray={this.state.prefCall}
              tablename={this.state.listName}
              handleClose={() => this.handleCloseRefresh()}
              loca={this.state.loca}
              handleClose2={() => {
                this.handleClose();
              }}
              isMobile={this.state.isMobile}
            ></PreferenceComponent>
          </Modal.Body>
          {/*         <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer> */}
        </Modal>

        <Modal
          size="xl"
          show={this.state.showmodel}
          onHide={this.handleModalList}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body className="mod-ht p-1">
            <ModelList
              setRef={(val, r_id) => this.setRef(val, r_id)}
              columnid={this.state.columnid}
              tabId={this.state.tabId}
              loca={this.state.loca}
              colBoolean={true}
              ref_filt={this.state.ref_filter}
              isMobile={this.state.isMobile}
              isVar={false}
            ></ModelList>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalList}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default WithNavigate(ListComponent);
