import React, { Component } from "react";
import axios from "axios";
import "../css/listcompo.css";
import tabular from "../cssMod/tabularNew.module.css";
import "../css/tabularReport.css";
import WorkInProgress from "./work_in_progress";
import NewFilterCompo from "./NewFilterCompo";
import PreferenceComponent from "./preference_component";
import { Modal, Button } from "react-bootstrap";
import WithNavigate from "./withNavigate";
import { faS } from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import "jspdf-autotable";
import Select from "react-select";
import { error } from "jquery";

class TabularReportNew extends Component {
  state = {
    name: "",
    list: [],
    tm_list: [],
    button: [],
    page_clicked: 0,
    page_count: 0,
    page_records: "",
    record_count: 0,
    showlist: false,
    showbtn: false,
    col_mn: [],
    column_depend: [],
    showupdownbtn: false,
    filtarray: [],

    filt: {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "", label: "" },
      ch: [],
    },
    filString: "",

    timeline: "",
    sortColumn: { name: "id", sort: false },
    columnid: "",
    tabId: "",
    loading: false,
    filter_unset: false,
    tablabel: "",
    page_error: false,
    error: "",
    noRecord: false,
    show: false,
    prefCall: [],
    loca: this.props.loca,
    srch: "",
    srch_column: "name",
    lst_record: [],
    colBoolean: this.props.colBoolean,
    tabname: "",
    columnList: [],
    ref_filt: [],
    isMobile: this.props.isMobile,
    isVar: this.props.isVar,
    tableName: this.props.tableName,
    other: this.props.otherLocation,
    id: "",
    reportName: "",
    defaultFilter: [],
    defaultTimeLine: "",
    reportlist: {},
    dynamicColList: [],
    dynamicColDetails: {},
    dynamicSelectedValue: {},
    dynamicSelectedLabel: "",
    singleCoulm: {},
    count: 1,
    selectedOption: null,
  };

  constructor(props) {
    super(props);
    this.alreadyRun = React.createRef();
    this.state.columnid = props.columnid;
    this.state.tabId = props.tabId;
    this.state.ref_filt = props.ref_filt;
    this.state.tabname = props.tabname;
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.filterItem = this.filterItem.bind(this);
    this.setList = this.setList.bind(this);
    this.filtersubmit = this.filtersubmit.bind(this);
    this.filterClear = this.filterClear.bind(this);
    this.showupdownbtn = this.showupdownbtn.bind(this);
    this.setSort = this.setSort.bind(this);
    this.callform = this.callform.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseRefresh = this.handleCloseRefresh.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.searchColumn = this.searchColumn.bind(this);
    this.setListRecord = this.setListRecord.bind(this);
    this.callSearchbtn = this.callSearchbtn.bind(this);
    this.callfilter = this.callfilter.bind(this);
    this.calltimeline = this.calltimeline.bind(this);
    this.callbtn = this.callbtn.bind(this);
    this.rightReadOnly = this.rightReadOnly.bind(this);
    this.leftReadOnly = this.leftReadOnly.bind(this);
    this.getChoiceRcd = this.getChoiceRcd.bind(this);
    this.callButton = this.callButton.bind(this);
    this.listOfTypes = [
      "filter",
      "filter_ref",
      "json",
      "group_key_value",
      "html",
      "multi_select"
    ];
    this.state.filtarray.push(this.state.filt);

    this.state.tm_list.push({ name: "", label: "None" });
    this.state.tm_list.push({ name: "current24", label: "Current 24" });
    this.state.tm_list.push({ name: "previous24", label: "Previous 24" });
    this.state.tm_list.push({ name: "week", label: "Week" });
    this.state.tm_list.push({ name: "last14days", label: "Last 14 Days" });
    this.state.tm_list.push({ name: "last30", label: "Last 30" });
    this.state.tm_list.push({ name: "thismonth", label: "This Month" });
    this.state.tm_list.push({ name: "last6month", label: "Last 6 Month" });
    this.state.tm_list.push({ name: "last1year", label: "Last 1 Year" });
    this.state.tm_list.push({ name: "all", label: "All" });
  }

  componentDidMount() {
    this.updateSearchParams();
    this.setList({}, true);
  }

  updateSearchParams = (prev) => {
    let newLocation = this.props.location;

    const { location } = this.props;

    const searchParams = new URLSearchParams(location.search);
    let rid = searchParams.get("rid");
    let reportName = searchParams.get("reportName");

    this.setState({
      id: rid,
      reportName: reportName,
    });
  };

  downloadImage = async () => {
    const doc = new jsPDF();
    const reportTitle = this.state.reportName;
    doc.setFontSize(18); // Set font size for the title
    doc.text(reportTitle, 14, 22); // Add title at position (x=14, y=22)

    // Optionally, add a line under the title
    doc.setLineWidth(0.5);
    doc.line(14, 25, 196, 25); // Draw line from x=14 to x=196 at y=25

    this.state.list.forEach((lstobj, l_i) => {
      const columns = lstobj.heading.map((obj) => obj.label);
      const rows = lstobj.rcd.map((objj) =>
        objj.colr.map((objr) =>
          objr.type === "filter" || objr.name === "json"
            ? JSON.stringify(objr.value)
            : objr.type === "group_key_value"
            ? JSON.stringify(objr.value.properties)
            : objr.value
        )
      );

      const total =
        lstobj.rcd?.length != 0 &&
        lstobj.heading.map((obj, o_i) =>
          obj.sum === "notNumber" ? "" : "Total " + obj.sum
        );

      rows.push(total);
      // Adding a space between title and table
      doc.autoTable({
        startY: 30, // Ensure table starts below the title
        head: [columns],
        body: rows,
      });

      // Adding a page break between tables if necessary
      if (l_i !== this.state.list.length - 1) {
        doc.addPage();
        doc.text(reportTitle, 14, 22); // Add the title on new pages
        doc.line(14, 25, 196, 25); // Draw line under title on new pages
      }
    });

    doc.save(this.state.reportName + this.state.count + ".pdf");
    this.setState({ count: this.state.count + 1 });
  };
  static getDerivedStateFromProps(props, state) {
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    let rid = searchParams.get("rid");

    if (rid !== state.id) {
      return {
        id: rid,
      };
    }
    return null;
  }

  leftReadOnly() {
    if (this.state.page_clicked === 1) {
      return true;
    } else {
      return false;
    }
  }

  rightReadOnly() {
    if (this.state.page_clicked === this.state.page_count) {
      return true;
    } else {
      return false;
    }
  }

  previousPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"' +
      this.state.tabId +
      '","colId":"' +
      this.state.columnid +
      '","value":"' +
      this.state.tabname +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked - 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filter.filter) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  nextPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"' +
      this.state.tabId +
      '","colId":"' +
      this.state.columnid +
      '","value":"' +
      this.state.tabname +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.page_clicked + 1) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filter.filter) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(pp);
  }

  firstPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"' +
      this.state.tabId +
      '","colId":"' +
      this.state.columnid +
      '","value":"' +
      this.state.tabname +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filter.filter) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';

    this.setList(pp);
  }

  lastPage() {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"' +
      this.state.tabId +
      '","colId":"' +
      this.state.columnid +
      '","value":"' +
      this.state.tabname +
      '","label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (this.state.record_count % 50 === 0
        ? this.state.page_count - 1
        : this.state.page_count) +
      '","page_records":"0"}}';
    pp += this.setSort();
    pp += ',{"filter":' + JSON.stringify(this.state.filter.filter) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(pp);
  }

  showupdownbtn(hd) {
    var sarray = this.state.sortColumn;
    let flt = JSON.parse(JSON.stringify(this.state.filtarray));
    if (!this.alreadyRun.current) {
      flt.pop();
      let ff = {
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "",
        rf: { id: "", value: "" },
        dc: { id: "", value: "", label: "" },
      };
      flt.push(ff);
    }
    var clmn = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","value":"loom"}}';
    pp +=
      ',{"table":{"id":"' +
      this.state.tabId +
      '","colId":"' +
      this.state.columnid +
      '","value":"' +
      this.state.tabname +
      '", "label":"' +
      this.state.tablabel +
      '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          if (this.state.list[l].heading[ll].name === hd) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '"}}';
      } else {
        pp += ',{"sort":{"asc":"false","column":"' + clmn + '"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < this.state.list.length; li++) {
        for (var lll = 0; lll < this.state.list[li].heading.length; lll++) {
          if (this.state.list[li].heading[lll].name === hd) {
            clmn = this.state.list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '"}}';
      } else {
        pp += ',{"sort":{"asc":"false","column":"' + clmn + '"}}';
      }
    }
    this.setState({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(flt) + "}";
    pp += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(pp);
  }

  setSort() {
    var clmn = "";
    var pp = "";
    var srt = this.state.sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < this.state.list.length; l++) {
        for (var ll = 0; ll < this.state.list[l].heading.length; ll++) {
          if (
            this.state.list[l].heading[ll].name === this.state.sortColumn.name
          ) {
            clmn = this.state.list[l].heading[ll].name;
            break;
          }
        }
      }
      if (this.state.sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '"}}';
      } else {
        pp += ',{"sort":{"asc":"false","column":"' + clmn + '"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"false","column":"' +
        this.state.list[0].heading[0].name +
        '"}}';
    }
    return pp;
  }

  getDynamicFilterByType = (obb, filter) => {
    let dff = [...this.state.defaultFilter.filter];

    let addRef = false;
    if (dff.length != 0 && (dff[0].an || dff[0].rf.id)) {
      addRef = true;
    }
    console.log("dff" ,dff);
    
    let ob = this.state.dynamicColDetails;
    if (ob.type === "reference") {
      filter[0].mc = "=";
      filter[0].rf.id = obb.id;
      filter[0].ct = ob.type;
      filter[0].co = ob.name;
      filter[0].cl = ob.label;
    } else if (ob.type == "choice") {
      filter[0].mc = "is";
      filter[0].an = obb.name;
      filter[0].ct = ob.type;
      filter[0].co = ob.name;
      filter[0].cl = ob.label;
    }
    if (addRef) filter[0].af = "AND";
  };

  getDynamicRcd = (e, type, selectedIndex) => {
    /*  if(type) */

    let filter = [
      {
        ct: "",
        af: "",
        mc: "",
        cl: "",
        co: "",
        an: "",
        rf: { id: "", value: "" },
        dc: { id: "", value: "", label: "" },
      },
    ];
    let timeline = "";

    let ob = this.state.dynamicColDetails;

    if (type == "field") {
      if (e.target.value) {
        let obb = this.state.dynamicColList[selectedIndex];
        this.setState({ dynamicSelectedValue: obb });
        this.getDynamicFilterByType(obb, filter);
      } else {
        let obb = {};
        this.setState({ dynamicSelectedValue: obb });
      }
      timeline = this.state.timeline;
    }

    if (type == "timeline") {
      let obb = this.state.dynamicSelectedValue;
      if (ob && JSON.stringify(obb) !== "{}") {
        this.getDynamicFilterByType(obb, filter);
      }
      timeline = e.target.value;
      if (!timeline) timeline = "";
      this.setState({ timeline: timeline });
    }

    let dff = filter;
    console.log("501" ,dff);
    
    if (filter[0].af) {
      dff = [...this.state.defaultFilter.filter];
      dff.push(filter[0]);
    }

    let rad = { filter: dff, timeline: timeline };
    console.log("rad" ,rad);
    
    if (filter[0].rf.id === "" && filter[0].an == "" && timeline === "") {
      rad = this.state.defaultFilter;
    }
    this.setList(rad);
  };

  setList(obj, isFirstCall) {
    var token = localStorage.getItem("token");
    this.setState({ loading: true });
    let recordId = this.state.id;
    axios
      .post(this.state.loca + "/loom/get/tabular/report/" + recordId, obj, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          this.setListRecord({ listrecord, isFirstCall });
          this.setState({ loading: false });
          this.getDynamicColumn();
        },
        (error) => {
          this.props.showErrorCompo(error);
        }
      );
  }

  getDynamicColumn = () => {
    var token = localStorage.getItem("token");
    //  this.setState({ loading: true });
    let recordId = this.state.id;
    axios
      .get(
        this.state.loca +
          "/loom/get/tabular/report/dynamicColsData/" +
          recordId,
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          const dynamicCoulmn = resp.data;
          console.log("record" ,dynamicCoulmn);
          
          let dynamic = dynamicCoulmn.dynamicColsData;

          //  var cla = []
          dynamic.unshift({ label: "None", name: "None" });
          this.setState({
            dynamicColList: dynamic,
          });
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
  };

  setListRecord({ listrecord, isFirstCall }) {
    var columnarry = [];
    var col_list = [];
    var hd = [];
    var va_ll = [];
    let rcdCount = 1;
    // var pagecount = {};
    console.log("listrecord" ,listrecord);
    
    if (listrecord !== "") {
      if ("Error" in listrecord) {
        this.setState({
          loading: false,
          page_error: true,
          error: listrecord.Error,
        });
      } else {
        var scnd = listrecord.formRecordList[2];
        if ("Error" in scnd) {
          if (scnd.Error === "No record found.") {
            this.setState({ list: [] });
          }
        } else {
          var page_clicked1 = parseInt(
            listrecord.formRecordList[3].page.page_clicked
          );
          var page_count1 = parseInt(
            listrecord.formRecordList[3].page.page_count
          );
          var page_records1 = listrecord.formRecordList[3].page.page_records;
          var record_count1 = parseInt(
            listrecord.formRecordList[3].page.record_count
          );

          var flt = listrecord.formRecordList[5];
          var fltarr = listrecord.formRecordList[5].filter;
          var tmln = listrecord.formRecordList[6].timeLine;

          if (isFirstCall) {
            let ftt = { filter: fltarr, timeline: tmln };
            this.setState({ defaultFilter: JSON.parse(JSON.stringify(ftt)) });
          }
          var filterString = "";
          var leng = fltarr.length;
          for (var f = 0; f < fltarr.length; f++) {
            if (leng === 1 && fltarr[f].cl === "") {
              break;
            } else {
              if (f > 0) {
                filterString += fltarr[f].af + " ";
              }
              filterString += fltarr[f].cl + " ";
              filterString += fltarr[f].mc + " ";
              if (
                fltarr[f].ct === "reference" ||
                fltarr[f].ct === "multi_select"
              ) {
                filterString += fltarr[f].rf.value + " ";
              } else {
                filterString += fltarr[f].an + " ";
              }
            }
          }
          if (listrecord.formRecordList[2].records.length === 0) {
            this.setState({ noRecord: true });
          } else {
            this.setState({ noRecord: false });
          }
          for (
            var i = 0;
            i < listrecord.formRecordList[2].records.length;
            i++
          ) {
            var in_vl = [];
            var record_id;
            var ref_value;
            for (
              var j = 0;
              j < listrecord.formRecordList[2].records[i].record.length;
              j++
            ) {
              if (j === 0) {
                record_id =
                  listrecord.formRecordList[2].records[i].record[j].value;
                if (
                  listrecord.formRecordList[2].records[i].record[j].refDisplay
                    ?.type === "reference"
                ) {
                  ref_value =
                    listrecord.formRecordList[2].records[i].record[j].refDisplay
                      .value.value;
                } else {
                  ref_value =
                    listrecord.formRecordList[2].records[i].record[j].refDisplay
                      .value;
                }
              } else if (j === 1) {
                if (
                  listrecord.formRecordList[2].records[i].record[j].type ===
                  "reference"
                ) {
                  in_vl.push({
                    value: rcdCount,
                    type: "",
                    isSrNo: true,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    ref_value: "",
                  });
                  rcdCount = rcdCount + 1;
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value
                        .value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: true,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    ref_value: ref_value,
                  });
                } else {
                  in_vl.push({
                    value: rcdCount,
                    type: "",
                    isSrNo: true,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    ref_value: "",
                  });
                  rcdCount = rcdCount + 1;

                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: true,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    ref_value: ref_value,
                  });
                }
              } else {
                if (
                  listrecord.formRecordList[2].records[i].record[j].type ===
                  "reference"
                ) {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value
                        .value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: false,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                  });
                } else {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: false,
                    table: listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                  });
                }
              }
            }
            va_ll.push({ colr: in_vl });
          }

          for (
            var p = 0;
            p < listrecord.formRecordList[10].preference.length;
            p++
          ) {
            hd.push({
              label: listrecord.formRecordList[10].preference[p].label,
              name: listrecord.formRecordList[10].preference[p].name,
              sum: listrecord.formRecordList[10].preference[p].sum,
            });
          }

          //   let dynamic = listrecord.formRecordList[7].dynamicColumn;
          let singleCoulm = listrecord.formRecordList[7].singleCoumn;
          // adding sr number in heading and rcd

          hd.unshift({ label: "Sr No", name: "Sr No", sum: "notNumber" });

          columnarry.push({ heading: hd, rcd: va_ll });
          var cla = JSON.parse(
            JSON.stringify(listrecord.formRecordList[9].column)
          );

          let dynamicColDetails =
            listrecord.formRecordList[11].dynamicColumnDetails;

          // var cla = []
          // dynamic.unshift({ label: "None", name: "None" });

          cla.unshift({ label: "None", name: "none" });
          this.setState({
            tablabel: listrecord.formRecordList[1].table.label,
            tableName: listrecord.formRecordList[1].table.value,
            prefCall: listrecord.formRecordList[9]?.column,
            button: listrecord.formRecordList[11]?.button,
            list: columnarry,
            reportlist: columnarry,
            page_clicked: page_clicked1,
            page_count: page_count1,
            page_records: page_records1,
            record_count: record_count1,
            col_mn: cla,
            filter: flt,
            filtarray: fltarr,
            timeline: tmln,
            filString: filterString,
            columnList: col_list,
            singleCoulm: singleCoulm,
            dynamicColDetails: dynamicColDetails,
          });
        }
      }
    }
    this.setState({ loading: false });
  }

  callform(nam, r_id, refDisplay) {
    if (nam === "first") {
      this.props.setRef(refDisplay, r_id);
    } else if (nam === "second") {
    }
  }

  callButton(nam, tab, r_id) {
    // this.props.showFormCompo(tab, 0, "new");
    this.props.handleClose();
    const { navigateWithSearchParams } = this.props;
    navigateWithSearchParams(
      "/private/form",
      {
        rty: "new",
        tableName: tab,
        rid: r_id,
      },
      {}
    );
  }

  dismiss() {
    this.props.unmountMe();
  }

  filterItem() {
    this.setState({ showlist: !this.state.showlist });
    this.setState({ showbtn: !this.state.showbtn });
  }

  filterClear() {
    // var fltarray = [];
    // fltarray.push(this.state.filt);
    // this.setState({ filtarray: fltarray, noRecord: false });
    // var tm = "";
    // this.setState({ timeline: tm });
    // var pp = '{"formRecordList":[';
    // pp += '{"application":{"id":"","value":"loom"}}';
    // if (this.state.other === true) {
    //   pp +=
    //     ',{"table":{"id":"","value":"' + this.state.tabname + '","label":""}}';
    // } else {
    //   pp +=
    //     ',{"table":{"id":"' +
    //     this.state.tabId +
    //     '","colId":"' +
    //     this.state.columnid +
    //     '","value":"' +
    //     this.state.tabname +
    //     '"}}';
    // }
    // pp += ',{"records":[]}';
    // pp +=
    //   ',{"page":{"record_count":"0","page_count":"1",' +
    //   '"page_clicked":"1","page_records":"0"}}';
    // pp += this.setSort();
    // pp += '{"filter":' + JSON.stringify(this.state.defaultFilter) + "}";
    // pp += ',{"timeLine":"' + tm + '"}"}

    let pp = {
      filter: this.state.defaultFilter,
      timeline: this.state.defaultTimeLine,
    };
    this.setList(pp);
  }

  filtersubmit() {
    // this.alreadyRun.current = true;
    // var fs = '{"formRecordList":[';
    // fs += '{"application":{"id":"","value":"loom"}}';
    // if (this.state.other === true) {
    //   fs +=
    //     ',{"table":{"id":"","value":"' + this.state.tabname + '","label":""}}';
    // } else {
    //   fs +=
    //     ',{"table":{"id":"' +
    //     this.state.tabId +
    //     '","colId":"' +
    //     this.state.columnid +
    //     '","value":""}}';
    // }
    // fs += ',{"records":[]}';
    // fs +=
    //   ',{"page":{"record_count":"0","page_count":"1",' +
    //   '"page_clicked":"1","page_records":"0"}}';
    // fs += ',{"sort":{"asc":"false","column":"id"}}';
    // fs += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    // fs += ',{"timeLine":"' + this.state.timeline + '"}]}';
    let fs = { filter: this.state.filtarray, timeline: this.state.timeline };
    let len = this.state.filtarray.length;
    if (this.state.filtarray[len - 1].co !== "") {
      if (
        this.state.filtarray[len - 1].an !== "" ||
        this.state.filtarray[len - 1].rf.value !== "" ||
        this.state.filtarray[len - 1].dc.value !== ""
      ) {
        this.setList(fs);
      }
    } else {
      this.setList(fs);
    }

    // } else {
    // 	this.setState({ filt: fil });
    // }
  }

  async callfilter(filtarray, in_index, col_id) {
    var rcd = this.state.list;
    if (col_id !== -1) {
      for (let i = 0; i < rcd.length; i++) {
        if (
          filtarray[in_index].ct === "choice" &&
          filtarray[in_index].ch.length === 0
        ) {
          let chc = await this.getChoiceRcd(col_id).then((res) => {
            if (res.length > 0) {
              filtarray[in_index].ch = res;
              filtarray[in_index].an = res[0].name;
            } else {
              filtarray[in_index].ch = [];
            }
          });
        }
      }
    }
    this.setState({
      filtarray: filtarray,
    });
  }

  getMultiSelectValues=(obj)=>{
    console.log(obj);
    let rcd=obj.record;
    let res="";
    if(rcd?.length!=0){
      for(let k=0; k<10 && k<rcd?.length;k++){
        if(k>0)
          res+=",";
         res=res+ rcd[k]?.value
      }
    }
    console.log(res);
    return res;
  }

  checkIfTypeNotExists = (type) => {
    for (let i = 0; i < this.listOfTypes.length; i++) {
      if (this.listOfTypes[i] === type) {
        return false;
      }
    }
    return true;
  };

  async getChoiceRcd(col_id) {
    var token = localStorage.getItem("token");
    let ck = await axios
      .get(this.state.loca + "/loom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          if ("Error" in res) {
            this.setState({
              loading: false,
              page_error: true,
              error: res.Error,
            });
          } else {
            let chk = res.choiceRecords;
            chk.unshift({ name: "none", value: "None" });
            return chk;
          }
        },
        (error) => {
          this.props.showErrorCompo();
        }
      );
    return ck;
  }

  calltimeline(timeline) {
    this.setState({
      timeline: timeline,
    });
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleCloseRefresh() {
    this.setState({ show: false });
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","value":"loom"}}';
    if (this.state.other === true) {
      tablere +=
        ',{"table":{"id":"","value":"' + this.state.tabname + '","label":""}}';
    } else {
      tablere +=
        ',{"table":{"id":"' +
        this.state.tabId +
        '","colId":"' +
        this.state.columnid +
        '","value":""}}';
    }

    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"false","column":"id"}}';
    tablere += ',{"filter":' + JSON.stringify(this.state.filtarray) + "}";
    tablere += ',{"timeLine":"' + this.state.timeline + '"}]}';
    this.setList(tablere);
  }

  callbtn(nam) {
    var btn = this.state.button;
    var mnrecord = this.state.mainrecord;
    var btntype = "";
    var calltype = "";
    var foundbtn = false;

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].name === nam) {
        btntype = btn[ij].buttonWebType;
        calltype = btn[ij].call_type;
        foundbtn = true;
        break;
      }
    }
    if (foundbtn === true && btntype !== "" && calltype !== "") {
      this.setState({
        page_error: false,
        error: "",
        loading: true,
        page_message: false,
        message: "",
      });
      if (calltype === "module") {
        this.callButton("new", this.state.tableName, 0);
      } else {
        mnrecord.formRecord[2].record = this.state.record;
        var token = localStorage.getItem("token");
        axios
          .post(this.state.loca + btn[ij].webUrl, mnrecord, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const rcd = resp.data;
            if (rcd !== "") {
              if ("Error" in rcd) {
                this.setState({
                  loading: false,
                  page_error: true,
                  error: rcd.Error,
                });
              } else {
                this.setState({ loading: false });
                var msg = rcd.formRecord[4].message;
                if (msg !== "") {
                  this.setState({ page_message: true, message: msg });
                }
                if (btntype === "NextPage") {
                  // var nextP = btn[ij].nextPage;
                  var nextP = rcd.formRecord[3].button.nextPage;
                  if (nextP === "List") {
                    this.callNextPage();
                  }
                }
              }
            }
          });
      }
      // } else {
      // 	this.setState({
      // 		page_error: true,
      // 		error: error_String,
      // 	});
      // 	document.body.scrollTop = 0;
      // 	document.documentElement.scrollTop = 0;
      // 	// this.props.unmountMe();
      // }
    }
  }

  changeSearch(val) {
    if (val === "") {
      this.setState({ srch: "" });
      this.filterClear();
    } else {
      this.setState({ srch: val });
    }
  }

  callSearchbtn() {
    var fld = this.state.srch_column;
    var val = this.state.srch;
    var col = this.state.columnList;
    var type = "";
    var label = "";
    for (var c = 0; c < col.length; c++) {
      if (fld === col[c].name) {
        type = col[c].type;
        label = col[c].label;
      }
    }
    if (val.length > 2) {
      let filter = [
        {
          ct: type,
          af: "",
          mc: "contains",
          cl: label,
          co: fld,
          an: val,
          rf: { id: "", value: "" },
        },
      ];

      var fs = '{"formRecordList":[';
      fs += '{"application":{"id":"","value":"loom"}}';
      if (this.state.other === true) {
        fs +=
          ',{"table":{"id":"","value":"' +
          this.state.tabname +
          '","label":""}}';
      } else {
        fs +=
          ',{"table":{"id":"' +
          this.state.tabId +
          '","colId":"' +
          this.state.columnid +
          '","value":"' +
          this.state.tabname +
          '"}}';
      }
      fs += ',{"records":[]}';
      fs +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"1","page_records":"0"}}';
      fs += ',{"sort":{"asc":"false","column":"id"}}';
      fs += ',{"filter":' + JSON.stringify(filter) + "}";
      fs += ',{"timeLine":""}]}';
      this.setList(fs);
    }
  }

  searchColumn(e) {
    this.setState({ srch_column: e.target.value });
  }
  handleSelectChange = (selectedOption) => {
    this.setState({
      dynamicSelectedLabel: selectedOption.label,
      selectedOption: selectedOption,
    });
    const selectedIndex = selectedOption.dataIndex;
    this.getDynamicRcd(
      { target: { value: selectedOption.value } },
      "field",
      selectedIndex
    );
  };

  render() {
    const options = this.state.dynamicColList?.map((tm, tm_o) => ({
      value: tm.id,
      label: tm.label ? tm.label : tm.name,
      dataIndex: tm_o,
    }));

    return (
      <div>
        {this.state.loading === true ? (
          <div>
            <div className="g-compo-loader2">
              <WorkInProgress
                tableName={this.state.listName}
                type={this.state.rty}
              ></WorkInProgress>
            </div>
          </div>
        ) : (
          <div>
            <div className="pagesetup">
              <div>
                {this.state.page_error === true && (
                  <div className="alert alert-danger form_alt" role="alert">
                    {this.state.error}
                  </div>
                )}
                {this.state.isMobile || window.innerWidth < 1100 ? (
                  <div className="flex bck bck-rel text-center" style={{}}>
                    <div className="-2">
                      <span className="obj_head ">{this.state.reportName}</span>
                    </div>
                    <div className="filter-ic">
                      {/*         {this.state.showlist === false && (
               <select
                 className="form-select namelist-mob"
                 aria-label="Default"
                 value={this.state.srch_column}
                 onChange={(e) => this.searchColumn(e)}
               >
                 {this.state.columnList.map((obj2, index) => (
                   <option key={index} value={obj2.name}>
                     {obj2.label}
                   </option>
                 ))}
               </select>
             )}
             {this.state.showlist === false && (
               <input
                 className="srch-mob form-control"
                 type="search"
                 aria-label="Search"
                 value={this.state.srch}
                 aria-hidden="true"
                 onChange={(e) => this.changeSearch(e.target.value)}
               ></input>
             )}
             {this.state.showlist === false && (
               <input
                 className="csm_btn csm_btn_pri col-md-2 sub-btn"
                 type="button"
                 value="Search"
                 aria-hidden="true"
                 onClick={() => this.callSearchbtn()}
               ></input>
             )} */}
                    </div>
                    <div
                      className="d-flex mt-2"
                      style={{ justifyContent: "center", fontSize: "20px" }}
                    >
                      {/*    <div>
               <i
                 className="fa fa-filter vlpointer pdtop5"
                 aria-hidden="true"
                 onClick={this.filterItem}
               ></i>
               <i
                 className="fa fa-cog vlpointer cog_pd pdtop5"
                 aria-hidden="true"
                 onClick={this.handleShow}
               ></i>
             </div>
             <div className="">
               <span style={{ marginLeft: "0.5em" }}>
                 <input
                   className=" mybt"
                   type="button"
                   value="<<"
                   onClick={this.firstPage}
                   disabled={this.leftReadOnly()}
                 />
                 <input
                   className=" mybt"
                   type="button"
                   value="<"
                   onClick={this.previousPage}
                   disabled={this.leftReadOnly()}
                 />
               </span>
               <span className="form-control in-put">
                 {this.state.page_records}
               </span>

             
               <span className="text-uppercase spfont"> of </span>
               <span className="rcd_count spfont">
                 {this.state.record_count}
               </span>
               <span>
                 <input
                   className=" mybt"
                   type="button"
                   value=">"
                   onClick={this.nextPage}
                   disabled={this.rightReadOnly()}
                 />
               </span>
               <span>
                 <input
                   className=" mybt"
                   type="button"
                   value=">>"
                   disabled={this.rightReadOnly()}
                   onClick={this.lastPage}
                 />
               </span>
             </div> */}
                    </div>
                    <div className="col-lg mt-2">
                      {
                        <div className="row">
                          <div className="col-8">
                            {/*              {this.state.showlist === true && (
                   <input
                     className="csm_btn csm_btn_pri col-md-2 sub-btn"
                     type="button"
                     value="Run"
                     aria-hidden="true"
                     onClick={this.filtersubmit}
                   ></input>
                 )}
                 {this.state.showlist === true && (
                   <input
                     className="csm_btn csm_btn_pri col-md-2 sub-btn"
                     type="button"
                     value="Clear"
                     aria-hidden="true"
                     onClick={this.filterClear}
                   ></input>
                 )} */}
                          </div>
                          <div className="col-4">
                            {/* {this.state.button?.map(
                   (obj, oo_i) =>
                     obj.ui_type === "formButton" && (
                       <input
                         type="button"
                         className="csm_btn csm_btn_pri col-md-2 sub-btn"
                         key={oo_i}
                         onClick={(e) => this.callbtn(obj.name)}
                         value={obj.value}
                       ></input>
                     )

                 )} */}
                            <i
                              // type="button"
                              // className="csm_btn csm_btn_pri col-md-2 sub-btn"
                              title="Download"
                              className="fa fa fa-download"
                              onClick={() => {
                                this.downloadImage();
                              }}
                              // value={"Download"}
                            ></i>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                ) : (
                  <div className="row bck ">
                    <div className=" col-lg-4 col-md-5 ps-md-2 filter-ic tabView">
                      {/*            {this.state.showlist === false && (
               <select
                 className="form-select namelist"
                 aria-label="Default"
                 value={this.state.srch_column}
                 onChange={(e) => this.searchColumn(e)}
               >
                 {this.state.columnList.map((obj2, index) => (
                   <option key={index} value={obj2.name}>
                     {obj2.label}
                   </option>
                 ))}
               </select>
             )} */}

                      {/*             {this.state.showlist === false && (
               <input
                 className="srch form-control"
                 type="search"
                 aria-label="Search"
                 value={this.state.srch}
                 aria-hidden="true"
                 onChange={(e) => this.changeSearch(e.target.value)}
                 onKeyDown={(e) => {
                   if (e.key === "Enter") {
                     this.callSearchbtn();
                   }
                 }}
               ></input>
             )} */}

                      {/*          {this.state.showlist === false && (
               <input
                 className="csm_btn csm_btn_pri col-md-2 sub-btn"
                 // className="insrtbtn2 btn btn-primary btn-sm"
                 type="button"
                 value="Search"
                 aria-hidden="true"
                 onClick={() => this.callSearchbtn()}
               ></input>
             )} */}
                    </div>

                    <div className=" col-lg-4 col-md-2 ps-md-4 text-center">
                      <span className="obj_head">{this.state.reportName}</span>
                    </div>
                    <div className="col-lg-4 col-md-5">
                      {/*       <div className="tpi">
               {this.state.button?.map(
                 (obj, oo_i) =>
                   obj.ui_type === "formButton" && (
                     <input
                       type="button"
                       className="csm_btn csm_btn_pri col-md-2 sub-btn"
                       key={oo_i}
                       onClick={(e) => this.callbtn(obj.name)}
                       value={obj.value}
                     ></input>
                   )
               )}
               <span style={{ marginLeft: "0.5em" }}>
                 <input
                   className=" mybt"
                   type="button"
                   value="<<"
                   onClick={this.firstPage}
                   disabled={this.leftReadOnly()}
                 />
               </span>
               <span>
                 <input
                   className=" mybt"
                   type="button"
                   value="<"
                   onClick={this.previousPage}
                   disabled={this.leftReadOnly()}
                 />
               </span>
               <span className="form-control in-put">
                 {this.state.page_records}
               </span>

 
               <span className="text-uppercase spfont"> of </span>
               <span className="rcd_count spfont feaffeafawfe">
                 {this.state.record_count}
               </span>
               <span>
                 <input
                   className=" mybt"
                   type="button"
                   value=">"
                   onClick={this.nextPage}
                   disabled={this.rightReadOnly()}
                 />
               </span>
               <span>
                 <input
                   className=" mybt"
                   type="button"
                   value=">>"
                   disabled={this.rightReadOnly()}
                   onClick={this.lastPage}
                 />
               </span>
             </div> */}

                      <div className="tpi" style={{marginRight:"5rem", cursor:"pointer", fontSize:"20px"}}>
                        {/* <span> */}
                        <i
                          // type="button"
                          // className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          title="Download"
                            className="fa fa fa-download"
                          onClick={() => {
                            this.downloadImage();
                          }}
                          // value={"Download"}
                        ></i>
                        {/* </span> */}
                      </div>
                    </div>
                  </div>
                )}
                {/*    {this.state.showlist === false && this.state.filString !== "" && (
         <div>{this.state.filString}</div>
       )} */}

                {this.state.isMobile || window.innerWidth < 1100 ? (
                  <div>
                    <div
                      className="disfl"
                      style={{ marginTop: "3px", marginBottom: "10px" }}
                    >
                      {Object.keys(this.state.dynamicColDetails).length !=
                        0 && (
                        <div className="pdrt10 pdlf10 pdtp5">
                          {this.state.dynamicColDetails?.label} :
                        </div>
                      )}
                      {Object.keys(this.state.dynamicColDetails).length !=
                        0 && (
                        <div className="fntSz15">
                          <Select
                            options={options}
                            onChange={this.handleSelectChange}
                            value={this.state.selectedOption}
                            isSearchable
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className="disfl"
                      style={{ marginTop: "3px", marginBottom: "10px" }}
                    >
                      <div className="pdrt10 pdlf10 pdtp5">Time Line :</div>
                      <div className="fntSz15">
                        <select
                          className="inpt_typefil inpu_rad"
                          type="text"
                          onChange={(e) => this.getDynamicRcd(e, "timeline")}
                          value={this.state.timeline}
                        >
                          {this.state.tm_list.map((tm, tm_o) => (
                            <option value={tm.name} key={tm_o}>
                              {tm.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div style={{ display: "flex" }}>
                      <div
                        className="disfl"
                        style={{ marginTop: "3px", marginBottom: "10px" }}
                      >
                        {Object.keys(this.state.dynamicColDetails).length !=
                          0 && (
                          <div className="pdrt10 pdlf10 pdtp5">
                            {this.state.dynamicColDetails?.label} :
                          </div>
                        )}
                        {console.log("selectedOption",options, this.state.selectedOption )
                        }
                        {Object.keys(this.state.dynamicColDetails).length !=
                          0 && (
                          <div className="fntSz15">
                            <Select
                              options={options}
                              onChange={this.handleSelectChange}
                              value={this.state.selectedOption}
                              isSearchable
                            />
                          </div>
                        )}
                      </div>

                      <div
                        className="disfl"
                        style={{ marginTop: "3px", marginBottom: "10px" }}
                      >
                        <div className="pdrt10 pdlf10 pdtp5">Time Line :</div>
                        <div className="fntSz15">
                          <select
                            className="inpt_typefil inpu_rad"
                            type="text"
                            onChange={(e) => this.getDynamicRcd(e, "timeline")}
                            value={this.state.timeline}
                          >
                            {this.state.tm_list.map((tm, tm_o) => (
                              <option value={tm.name} key={tm_o}>
                                {tm.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* {this.stae.list.length === 0 && <div className="norcd">No Record Found</div>} */}
                {Object.keys(this.state.singleCoulm).length != 0 &&
                  this.state.list?.length != 0 &&
                  this.state.list[0].rcd.length != 0 && (
                    <div style={{ marginBottom: "10px", paddingLeft: "10px" }}>
                      {this.state.singleCoulm.type === "reference" ? (
                        <h6>
                          {this.state.singleCoulm?.label + " = "}{" "}
                          {this.state.singleCoulm?.value?.value}
                        </h6>
                      ) : (
                        <h6>
                          {this.state.singleCoulm?.label + " = "}{" "}
                          {this.state.singleCoulm?.value}
                        </h6>
                      )}
                    </div>
                  )}
                <div style={{ marginTop: "6px" }}>
                  {this.state.list.map((lstobj, l_i) => (
                    <div
                      className="heading_top table_set over mdheight"
                      key={l_i}
                    >
                      <table
                        className="table table-bordered table-striped table-hover p-1"
                        style={{ overflow: "auto" }}
                      >
                        <thead /* className="sticky-header_tabular" */
                          style={{
                            position: "sticky",
                            top: "0",
                          }}
                        >
                          {/* top-0 */}
                          <tr className={`${tabular.obj_name_tabular}`} style={{backgroundColor:"#282a3a", color:"white", whiteSpace: "nowrap"}}>
                            {lstobj.heading.map((obj, o_i) => (
                              <th
                                // className="vlpointer"
                                /*     onClick={(e) => this.showupdownbtn(obj.name)} */
                                key={o_i}
                              >
                                {obj.label}
                                {this.state.sortColumn.name === obj.name &&
                                  this.state.sortColumn.sort === true && (
                                    <i /* className=" icpadding fa fa-arrow-up" */
                                    ></i>
                                  )}
                                {this.state.sortColumn.name === obj.name &&
                                  this.state.sortColumn.sort === false && (
                                    <i /* className=" icpadding fa fa-arrow-down" */
                                    ></i>
                                  )}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {lstobj.rcd.map((objj, ob_i) => (
                            <tr
                              className={`${tabular.obj_value_tabular}`}
                              key={ob_i}
                            >
                              {objj.colr.map((objr, or_i) => (
                                <td
                                  key={or_i}
                                  className={
                                    objr.firstrecord === true
                                      ? "val_pad  vlpointer"
                                      : "val_pad"
                                  }
                                  // onClick={
                                  //   objr.firstrecord === true
                                  //     ? (e) =>
                                  //         this.callform(
                                  //           "first",
                                  //           objr.r_id,
                                  //           objr.ref_value
                                  //         )
                                  //     : (e) => this.callform("second", "")
                                  // }
                                >
                                  {/* {objr.type === "filter" ||
                                  objr.name === "json"
                                    ? JSON.stringify(objr.value)
                                    : objr.type === "group_key_value"
                                    ? JSON.stringify(objr.value.properties)
                                    : objr.value} */}
                                    {objr.type === "filter" ||
                              objr.type === "filter_ref" ||
                              objr.name === "json" && (
                                JSON.stringify( objr.value)?.length > 50
                                ?  JSON.stringify( objr.value).slice(0, 50) + " ..."
                                :  JSON.stringify( objr.value)
                              
                              )} 
                              
                          { objr.type === "group_key_value" && (
                                JSON.stringify(
                                  objr.value.properties?.length > 50
                                    ? objr.value.properties.slice(0, 50) +
                                        " ..."
                                    : objr.value.properties
                                )
                              ) }
                              {objr.type === "html" && (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      objr.value?.length > 50
                                        ? objr.value.slice(0, 50) + " ..."
                                        : objr.value,
                                  }}
                                ></span>)}
                            {objr.type === "multi_select" && (

                              this.getMultiSelectValues(objr.value).length > 50 ? (
                                this.getMultiSelectValues(objr.value).slice(0, 50) + " ..."
                              ) : (
                                this.getMultiSelectValues(objr.value))
                             )}
                              
                            {this.checkIfTypeNotExists(objr.type)&&(
                               objr.value?.length > 50 ? (
                                objr.value.slice(0, 50) + " ..."
                              ) : (
                                objr.value
                              )
                            )}
                                </td>
                              ))}
                            </tr>
                          ))}
                          <tr className={`${tabular.obj_name_tabular}`}>
                            {lstobj.rcd?.length != 0 &&
                              lstobj.heading.map((obj, o_i) => (
                                <td
                                  className="vlpointer"
                                  style={{ fontWeight: "bold" }}
                                  key={o_i}
                                >
                                  {(obj.sum === "notNumber" || !obj.sum )
                                    ? ""
                                    : "Total " + obj.sum}
                                </td>
                              ))}
                          </tr>
                        </tbody>
                      </table>
                      {this.state.noRecord === true && (
                        <div className="norcd">No Record Found</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <Modal
                dialogClassName="my-modal"
                show={this.state.show}
                onHide={this.handleClose}
                // centered={true}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Personalized List Column</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <PreferenceComponent
                    pref="user"
                    setRef={(val) => this.setRef(val)}
                    columnarray={this.state.prefCall}
                    tablename={this.state.tableName}
                    handleClose={() => this.handleCloseRefresh()}
                    loca={this.state.loca}
                    isMobile={this.state.isMobile}
                    handleClose2={() => {
                      this.handleClose();
                    }}
                  ></PreferenceComponent>
                </Modal.Body>
                {/* <Modal.Footer>
       <Button variant="secondary" onClick={this.handleClose}>
         Close
       </Button>
     </Modal.Footer> */}
              </Modal>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WithNavigate(TabularReportNew);
