
export function formChange2({thiss}){

    thiss.setState({hook:"Danish Ansari"})
}


export function formChangefnunction  ({ thiss,
    vl /* value */,
    index /* index */,
    ob /* filed */,
    type,
    id,
    vrf,
    readonly,
    outerIndex,
    innerIndex,
    nme,
    obj,
    rcd_info ,axios}
  ) {
    if (type !== "multi_select") {
      thiss.setState({
        page_message: false,
        message: "",
        page_error: false,
        error: "",
      });
    }
    let otb = thiss.state.list;
    var frecord = otb[0].rcd[obj.rowIndex].colr;
    console.log("frecord" ,frecord);

   // thiss.ap_form.current = new ApForm(frecord, thiss.setRecord(""), thiss, {});
    // thiss.setState((prevState) => {
    //   const updatedMap = new Map(prevState.chanedColsAndValues);
    //   updatedMap.set(ob, vl);
    //   return { chanedColsAndValues: updatedMap };
    // });

    if (type === "reference") {
      if (ob === "form_group_id") {
        if (vl.properties) {
          thiss.state.keyValueJson.properties = [];
          let len = vl.properties.length;
          for (let i = 0; i < len; i++) {
            thiss.state.keyValueJson.properties.push({
              name: "",
              value: "",
            });
          }
          thiss.setState({ keyValueJson: thiss.state.keyValueJson });
        }
        for (let i = 0; i < frecord.length; i++) {
          if (frecord[i].name === "properties") {
            frecord[i].value = vl;
            break;
          }
        }
        thiss.setState({ record: frecord });
      }

      // if (thiss.tablename.current === "reference_filter") {
      //   if (ob === "loom_column_id") {
      //     thiss.callColumnByColId(id);
      //   }
      //   if (ob === "loom_table_id") {
      //     thiss.callTableColumn(vl, false, false, thiss.tablename.current, true);
      //   }
      // } else if (ob === "loom_table_id" || ob === "loomtable_id") {
      //   thiss.callTableColumn(vl, false, false, thiss.tablename.current);
      // } else if (ob === "reference_id") {
      //   thiss.callTableColumn(vl, false, true, thiss.tablename.current);
      // } else if (ob === thiss.state.fldType) {
      //   thiss.callTableColumn(vl, true, false, thiss.tablename.current);
      // } else {
      //   if (thiss.tablename.current === "report") {
      //     thiss.callColumnByColId(id);
      //   }
      // }
      if (vrf === false) {
        frecord[index].clicked = false;
      }
      if (vl !== "") {
        if (vl.length > 1) {
          //     if (obj) obj.showRecent = false;
          if (vrf === true) {
            if (frecord[index].name === ob) {
              //    console.log("setting its value");

              frecord[index].value.value = vl;
              frecord[index].value.id = id;
              frecord[index].clicked = true;
              frecord[index].verified = "verified";
              frecord[index].rcd_info = "true";
              // var rfrcd2 = thiss.state.refrecord;
              //  rfrcd2.record = [];

              // calling secondary filed relted to current reference fie;d
              // index ob type
              // thiss.getSecondaryFields(
              //   index,
              //   ob,
              //   type,
              //   id,
              //   frecord[index]?.refTable?.value
              // );
              thiss.setState({ list: otb });
              //  thiss.setState({ record: frecord});
              // thiss.validationfn(vl, index, ob, type, id);
            }
          } else {
            // thiss.setState({frecord[index].rcd_info : false});
            if (frecord[index].name === ob) {
              frecord[index].value.value = vl;
              frecord[index].value.id = id;
              frecord[index].rcd_info = "false";
              // frecord[index].verified = "unverified";
            //  thiss.setState({ record: frecord });
            thiss.setState({ list: otb });
            }
            var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
            veri += '{"tabvalue":"' + vl + '"}]}';
            var token = localStorage.getItem("token");
            var rff = [];
            axios
              .post(
                thiss.state.loca + "/loom/reference/record",
                veri.toString(),
                {
                  headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                  },
                }
              )
              .then(
                (resp) => {
                  const refrencercd = resp.data;
                  if (refrencercd !== "") {
                    if ("Error" in refrencercd) {
                      thiss.setState({
                        loading: false,
                        page_error: true,
                        error: refrencercd.Error,
                      });
                    } else {
                      var ref_rcd =
                        refrencercd.referenceRecordList[2].records.length;
                      if (ref_rcd >= 5) {
                        ref_rcd = 5;
                      }
                      for (var i = 0; i < ref_rcd; i++) {
                        rff.push(refrencercd.referenceRecordList[2].records[i]);
                      }
                    }
                  }
                  var rf = { index: index, record: rff };

                  if (refrencercd.referenceRecordList[2].records.length > 0) {
                    frecord[index].verified = "";
                  } else {
                    frecord[index].verified = "unverified";
                  }
                  frecord[index].value.value = vl;
                  frecord[index].value.id = id;
                //  thiss.setState({ refrecord: rf, record: frecord });
                thiss.setState({ list: otb });
                },
                (error) => {
                  let err = {
                    message: error.message,
                    code: error.response.status,
                  };
                 // thiss.props.showErrorCompo({ state: { err: err } });
                }
              );
          }
        } else {
          if (obj.recentSearch !== undefined && obj.recentSearch !== null) {
            if (obj.recentSearch.length >= 1) obj.showRecent = true;
          }
          if (frecord[index].name === ob) {
            frecord[index].value.value = vl;
            frecord[index].value.id = id;
            frecord[index].verified = "";
         //   var rfrcd3 = thiss.state.refrecord;
           // rfrcd3?.record = [];
            thiss.setState({ list: otb });
          }
        }
      } else {
        if (frecord[index].name === ob) {
          // frecord[index].value.value = vl;
          // frecord[index].value.id = id;
          // frecord[index].verified = "";
          // var rfrcd3 = thiss.state.refrecord;
          // rfrcd3.record = [];
          // thiss.setState({ record: frecord, refrecord: rfrcd3 });
          if (frecord[index].name === ob) {
            frecord[index].value.value = vl;
            frecord[index].value.id = id;
            frecord[index].verified = "";
         //   var rfrcd3 = thiss.state.refrecord;
           // rfrcd3?.record = [];
            thiss.setState({ list: otb });
          }
        }
      }
    } else if (type === "choice" || type === "boolean") {
      if (readonly === "false") {
        frecord[index].value = vl;
        thiss.setState({ list: otb });
      }
    } else if (type === "dynamic_key_value") {
      let props = vl;
      let abc = frecord[index].value[outerIndex];
      if (innerIndex === 0) {
        var key = Object.keys(abc)[0];
        var val = Object.values(abc)[0];
        delete abc[key];
        if (val) {
          abc[props] = val;
        } else {
          abc[props] = "";
        }
      } else {
        var key = Object.keys(abc)[0];
        if (key) {
          abc[key] = props;
        } else {
          abc[""] = props;
        }
      }
      // setRecord([...frecord]);
      //  thiss.setState({ record: frecord });
      thiss.setState({ list: otb });
    } else if (type === "email") {
      frecord[index].value = vl?.toLowerCase?.();
      thiss.setState({ list: otb });
    } else {
      if (frecord[index]?.verified) frecord[index].verified = "initial";
      if (frecord[index].name === ob) {
        if (frecord[index].type === "String") {
          if (frecord[index].validation === "number") {
            if (/^[0-9]*$/.test(vl)) {
              frecord[index].value = vl;
              thiss.setState({
                list: otb,
                validation_error: false,
                validation: "",
              });
            } else {
              document.getElementById("myPopup");
              thiss.setState({
                validation_error: true,
                validation: "Only Accept Number",
                ob: ob,
              });
            }
          } else if (frecord[index].validation === "character") {
            if (/^[a-zA-Z\s]*$/.test(vl)) {
              frecord[index].value = vl;
              thiss.setState({
                list: otb,
                validation_error: false,
                validation: "",
              });
            } else {
              document.getElementById("myPopup");
              thiss.setState({
                validation_error: true,
                validation: "Only Accept character",
                ob: ob,
              });
            }
          } else if (frecord[index].validation === "withoutSpecialCharacter") {
            if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(vl)) {
              frecord[index].value = vl;
              thiss.setState({ list: otb });
            }
          } else if (frecord[index].validation === "withSpecialCharacter") {
            // if (/^[ A-Za-z0-9_@.:,/#*&+-]*$/.test(vl)) {
            if (/^(?!.*[@.:,/*#&+-]{2,})[A-Za-z0-9_@.:,/*#&+' -]*$/.test(vl)) {
              frecord[index].value = vl;
              thiss.setState({ list: otb });
            }
          } else if (frecord[index].validation === "zipCode") {
            if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(vl)) {
              frecord[index].value = vl;
              thiss.setState({ list: otb });
            }
          } else if (frecord[index].validation === "decimal") {
            if (/^\d*\.?\d*$/.test(vl)) {
              frecord[index].value = vl;
              thiss.setState({ list: otb });
            }
          } else if (frecord[index].validation === "ipAddress") {
            if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(vl)) {
              frecord[index].value = vl;
              thiss.setState({ list: otb });
              //Ipv4 = (([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])
              //Ipv6 = ((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}
            }
          } else {
            // if (/^[a-zA-Z0-9_\s]*$/.test(vl)) {
            frecord[index].value = vl;
            thiss.setState({ list: otb });
            // }
          }
        } else if (frecord[index].type === "key_value") {
          const updatedProperties = [thiss.state.keyValueJson.properties];
          updatedProperties[outerIndex] = {
            name: nme,
            value: vl,
          };
          thiss.setState({ keyValueJson: { properties: updatedProperties } });
        } else if (frecord[index].type === "group_key_value") {
          if (nme === "name") {
            frecord[index].value.properties[outerIndex][nme] = vl;
          }
          if (nme === "choice" && innerIndex !== null) {
            frecord[index].value.properties[outerIndex][nme][innerIndex].value =
              vl;
          }
          thiss.setState({ list: otb });
        } else {
          frecord[index].value = vl;
          thiss.setState({ list: otb });
        }
      }
    }

    /*     setTimeout(()=>{
        },2000) */
    //  thiss.showRecent(obj, true);
  //  thiss.scriptFunc(ob, type, vl, obj.rowIndex);
    addInChangedRowAndCols({index, obj,thiss});

    console.log("callAginfm" , thiss.callAginfm.current);
    
    // if(!thiss.callAginfm.current) {
    //   thiss.callAginfm.current=true;
    //   thiss.callAgainFormChangeFn(index , obj);
     
    // }
  //  thiss.callAgainFormChangeFn(index , obj);
  };

export function handleUpdateSaveFn({rowIndex, colCell ,thiss,axios}){

    console.log(rowIndex ,colCell);
    
    console.log(thiss);
    
    console.log(thiss.state.list);
    
    let otb=thiss.state.list;
    otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = false;
 
       //  if(!thiss.callAginfm.current) {
       // thiss.callAginfm.current=true;
       callAgainFormChangeFn(colCell ,  otb[0].rcd[rowIndex].colr[colCell],thiss);
 
       setTimeout(()=>{
        updateRecord({ name: "update" },thiss ,axios);
       },500)
      
  //   }
  thiss.setState({list:otb})
  }

function callAgainFormChangeFn(colIndex , obj,thiss){

    console.log( "callAgainFormChangeFn",thiss.multipleSelectedRows.current);
    
    console.log("colIndex" ,colIndex , obj);
    let otb=thiss.state.list;
    if(thiss.multipleSelectedRows.current?.includes(obj?.rowIndex) && thiss.multipleSelectedRows.current?.length>1)
    {
       let att=thiss.multipleSelectedRows.current   
       for(let k=0;k<att?.length;k++){
  
          if(att[k] !=obj.rowIndex){
         
           let column = otb[0].rcd[att[k]].colr[colIndex];
           console.log("kk" ,k ,column);
         
      //  thiss.runScriptOnLoadWhenUpdate(column.rowIndex, colIndex);
         if(column.type==="reference"){
          thiss.formChangefn(obj.value.value ,colIndex ,column.name,column.type , obj.value.id , true ,column.uivalid.read_only,undefined,undefined,undefined,column)
         }else
           thiss.formChangefn(obj.value ,colIndex ,column.name,column.type , column.id , true ,column.uivalid.read_only,undefined,undefined,undefined,column)
          }
       }    
    }
    thiss.callAginfm.current=false;
  }

   function   removeInChangedRowsAndCols(index ,obj,thiss){
      // let rcd = [];
      // let changedRowAndCols = thiss.state.changedRowAndCols;
      let refList = thiss.state.refList;
      let list = thiss.state.list;
      console.log(index);
      console.log(obj);
      
    //  for (const [row, cols] of changedRowAndCols) {
        //console.log(`Key: ${key}, Value:`, value);
     //   for (let k = 0; k < cols?.length; k++) {
          let col = index;
          let unChanged = refList[0].rcd[obj.rowIndex].colr[col];
          let changed = list[0].rcd[obj.rowIndex].colr[col];
    
          console.log("unChanged", unChanged);
          console.log("changed", changed);
  
          if(unChanged.type==="reference"){
            thiss.formChangefn(unChanged.value.value ,index ,unChanged.name,unChanged.type , unChanged.value.id , true ,unChanged.uivalid.read_only,undefined,undefined,undefined,unChanged)
           }else{
             thiss.formChangefn(unChanged.value ,index ,unChanged.name,unChanged.type , unChanged.id , true ,unChanged.uivalid.read_only,undefined,undefined,undefined,unChanged)
            }
          
          // if (JSON.stringify(unChanged.value) !== JSON.stringify(changed.value)) {
          //   let obj = {
          //     formRecord: [
          //       { application: { id: "", value: "" } },
          //       { table: { id: "", value: thiss.state.tabName } },
          //       { record: list[0].rcd[row].colr },
          //     ],
          //   };
          //   rcd.push(JSON.stringify(obj));
          //   break;
          // }
      //  }
    //  }
  
    //   thiss.setState((prevState) => {
    //     const changedRowAndCols2 = new Map(prevState.changedRowAndCols);
    //     console.log("changedRowAndCols befor", changedRowAndCols2);
    //     if (changedRowAndCols2.has(obj.rowIndex)) {
    //       let collArr = changedRowAndCols2.get(obj.rowIndex);
    //       // let found = collArr.find?.((e) => e === index);
    //       // if (!found) collArr.push(index);
    //       let newCollArr = collArr.filter?.((e)=>e!=index);
    //       if(newCollArr?.length!=0){
    //         changedRowAndCols2.set(obj.rowIndex, newCollArr);
    //       }else{
    //         changedRowAndCols2.delete(obj.rowIndex)
    //       }
  
    //     }
    //     console.log("changedRowAndCols after", changedRowAndCols2);
    //     return { changedRowAndCols: changedRowAndCols2 };
    //   } 
    // );
  
    if (thiss.changedRowAndCols.current) {
      const changedRowAndCols2 = thiss.changedRowAndCols.current;
  
      if (changedRowAndCols2.has(obj.rowIndex)) {
        let collArr = changedRowAndCols2.get(obj.rowIndex);
        let newCollArr = collArr.filter?.((e) => e !== index);
        if (newCollArr?.length !== 0) {
          changedRowAndCols2.set(obj.rowIndex, newCollArr);
        } else {
          changedRowAndCols2.delete(obj.rowIndex);
        }
      }
      console.log("changedRowAndCols after", changedRowAndCols2);
      thiss.changedRowAndCols.current = changedRowAndCols2;
    }
  
    }

export function handleUpdateCancelFn ({rowIndex, colCell ,thiss})  {
      let otb=thiss.state.list;
      thiss.state.list[0].rcd[rowIndex].colr[colCell].editingCellIndex = false;
      removeInChangedRowsAndCols(colCell , thiss.state.list[0].rcd[rowIndex].colr[colCell],thiss);
      thiss.setState({list:otb})
    };


function   addInChangedRowAndCols  ({index, obj,thiss}) {

    if (!thiss.changedRowAndCols.current) {
      thiss.changedRowAndCols.current = new Map();
    }
    const changedRowAndCols2 = thiss.changedRowAndCols.current;
    if (changedRowAndCols2.has(obj.rowIndex)) {
      let collArr = changedRowAndCols2.get(obj.rowIndex);
      let found = collArr.find?.((e) => e === index);
      if (!found) collArr.push(index);
    } else {
      let collsArr = [];
      collsArr.push(index);
      changedRowAndCols2.set(obj.rowIndex, collsArr);
    }
  
    thiss.changedRowAndCols.current = changedRowAndCols2;
      console.log("after", thiss.state.list);
    };

    export function  validationFnunction ({vl, index, ob, type, id, obj,thiss,axios})  {
        let otb = thiss.state.list;
        var formrecord = otb[0].rcd[obj.rowIndex].colr;
        console.log("otb", otb);
        console.log("frecord", formrecord);
        console.log("obj", obj);
    
        if (type === "reference") {
          if (vl !== "") {
            if (formrecord[index].clicked === true) {
              formrecord[index].verified = "verified";
            } else {
              var token = localStorage.getItem("token");
              var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
              veri += '{"tabvalue":' + JSON.stringify(vl) + "}]}";
              axios
                .post(thiss.state.loca + "/loom/reference/verify", veri.toString(), {
                  headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                  },
                })
                .then(
                  (resp) => {
                    const refrencercd = resp.data;
                    if (refrencercd !== "") {
                      if (refrencercd.Result === "Unverified") {
                        formrecord[index].verified = "unverified";
                        thiss.setState({ list: otb });
                      } else {
                        formrecord[index].verified = "verified";
                        // var rfrcd = thiss.state.refrecord;
                        // rfrcd.record = [];
                        thiss.setState({ list: otb /* refrecord: []  */ });
                      }
                      thiss.setState({ list: otb });
                      return;
                    }
                  },
                  (error) => {
                    let err = {
                      message: error.message,
                      code: error.response.status,
                    };
                    thiss.props.showErrorCompo({ state: { err: err } });
                  }
                );
            }
          } else {
            formrecord[index].verified = "initial";
            thiss.setState({ list: otb });
          }
        } else {
          if (vl !== "") {
            var minLength = formrecord[index].uivalid.min_length;
            if (minLength !== 0 && vl.length < minLength) {
              thiss.setState({
                verify_error: `Length Should Be At Least ${minLength} Character Long`,
              });
              formrecord[index].verified = "min_unverified";
            } else {
              if (formrecord[index].name === ob) {
                if (vl !== "") {
                  formrecord[index].verified = thiss.fieldverify(
                    formrecord[index].type,
                    vl
                  );
                } else {
                  formrecord[index].verified = "initial";
                }
              }
            }
          }
          thiss.setState({ list: otb });
        }
    
      };

      export function    handleRowClickFn ({e, rowIndex ,colCel,thiss}) {
        console.log("rowIndex", rowIndex);
        e.preventDefault();
      let newMultipleSelectedRows=[];
    
     if(thiss.multipleSelectedRows.current?.length >1){
      newMultipleSelectedRows=thiss.multipleSelectedRows.current
     }
        
       if(colCel != thiss.state.multiSelectCol    || rowIndex != thiss.state.multiSelectrow){
           thiss.setState({multiSelectCol:colCel , multiSelectrow:rowIndex});
           newMultipleSelectedRows=[];
       }
       //  newMultipleSelectedRows  =[...thiss.multipleSelectedRows.current];
     if(!thiss.multipleSelectedRows.current)
             newMultipleSelectedRows=[];
    
            if (!newMultipleSelectedRows.includes(rowIndex)) {
    
            //  return { multipleSelectedRows: [...multipleSelectedRows, rowIndex] };
               newMultipleSelectedRows=[...newMultipleSelectedRows,rowIndex]
               console.log("newMultipleSelectedRows" ,newMultipleSelectedRows);
               thiss.multipleSelectedRows.current=newMultipleSelectedRows
            }else if(newMultipleSelectedRows.includes?.(rowIndex) && e.ctrlKey){
    
              newMultipleSelectedRows=[...newMultipleSelectedRows]
            //  console.log("newMultipleSelectedRows" ,newMultipleSelectedRows);
               newMultipleSelectedRows  = newMultipleSelectedRows.filter((row)=>row!=rowIndex)
              thiss.multipleSelectedRows.current=newMultipleSelectedRows
            }
      };

      export function   handleMouseDownFn ({rowIndex, colIndex, event , thiss ,document}) {
        if (event.shiftKey) {
          console.log("changinf ");
          
          thiss.setState({
            isDragging: true,
            dragStartCell: { rowIndex, colIndex },
          });
                // Prevent text selection during drag
                document.body.style.userSelect = "none";
          
                // Change cursor to + sign when dragging starts
                document.body.style.cursor = "crosshair";
        }
      };

      export function   setcolumnFn ({index, name, type, col, readonly, obj ,thiss}) {
          if (readonly === "false") {
            let otb = thiss.state.list;
            var frecord = otb[0].rcd[obj.rowIndex].colr;
            var rf = thiss.state.record_rq?.referenceQualifier;
      
            console.log("thiss" ,thiss);
            
            console.log("rff" ,rf);
            
            var ref_filt = thiss.state.ref_filter;
            ref_filt = [];
            for (var r = 0; r < rf.length; r++) {
              if (rf[r].loomColumn.id === col) {
                var filt = rf[r].filter.filter;
                for (var f = 0; f < filt.length; f++) {
                  if (
                    filt[f].mc === "is dependent" ||
                    filt[f].ct === "depend_table"
                  ) {
                    var addFilt = "";
                    if (f > 0) {
                      addFilt = filt[f].af;
                    }
                    var co_name = filt[f].rf.value; //rf
                    var co_label = filt[f].rf.label; //rf
                    let ab = thiss.getFieldValue(filt[f].dc.id, obj);
                    var filt1 = JSON.parse(JSON.stringify(thiss.state.mainFilt));
                    filt1.af = addFilt;
                    filt1.co = co_name;
                    filt1.cl = co_label;
                    filt1.ct = filt[f].ct;
                    filt1.mc = "=";
                    filt1.rf.id = ab.id;
                    filt1.rf.value = ab.value;
      
                    ref_filt.push(filt1);
                  } else {
                    ref_filt.push(filt[f]);
                  }
                }
              }
              if (rf[r].loomColumn.id === col && "filter" in rf[r]) {
                var filtt = rf[r].filter.filter;
                for (var d = 0; d < filtt.length; d++) {
                  if (filtt[d].ct === "depend_table") {
                    var col_name = filtt[d].rf.value;
                    var co_label = filt[f].rf.label;
                    let ab = thiss.getFieldValue(filtt[d].dc.id);
                    filtt = thiss.state.filt;
                    filtt.co = col_name;
                    filtt.co = co_label;
                    filtt.ct = filt[f].ct;
                    filtt.mc = "=";
                    filtt.rf.id = ab.id;
                    filtt.rf.value = ab.value;
                    ref_filt.push(filtt);
                  }
                }
              }
            }
            if (ref_filt.length === 0) {
              ref_filt.push(JSON.parse(JSON.stringify(thiss.state.mainFilt)));
            }
      
            thiss.setState({
              ref_filter: ref_filt,
              columnid: col,
              cur_ref_name: name,
              cur_ref_type: type,
              cur_col_index: index,
              cur_row_index: obj.rowIndex,
              cur_ref_obj: obj,
              showmodel: true,
            });
            thiss.openModalList();
          }
        };

        export function    handleDoubleClickOpenForUpdateFn  ({rowIndex, colCell,thiss})  {
            console.log("rowsScriptHasBeenRun", thiss.rowsScriptHasBeenRun);
            let otb=thiss.state.list;
            if (!otb[0].rcd[rowIndex].colr[colCell].editingCellIndex) {
              // if (!thiss.rowsScriptHasBeenRun?.current) {
              //   thiss.rowsScriptHasBeenRun.current = [];
              // }
           //   let row = thiss.rowsScriptHasBeenRun.current.find?.((e) => e == rowIndex);
              // if (row===undefined) {
              //   thiss.rowsScriptHasBeenRun.current.push?.(rowIndex);
              // //  thiss.runScriptOnLoadWhenUpdate(rowIndex, colCell);
              // }
              otb[0].rcd[rowIndex].colr[colCell].editingCellIndex = true;
            }
            thiss.setState({list:otb})
          };

        async  function    updateRecord   (btn ,thiss,axios)  {
            let rcd = [];
            let changedRowAndCols = thiss.changedRowAndCols.current;
            let refList = thiss.state.refList;
            let list = thiss.state.list;
            let dupRefList=[...thiss.state.refList];
            for (const [row, cols] of changedRowAndCols) {
              //console.log(`Key: ${key}, Value:`, value);
              for (let k = 0; k < cols?.length; k++) {
                let col = cols[k];
                let unChanged = refList[0].rcd[row].colr[col];
                let changed = list[0].rcd[row].colr[col];
        
                if (JSON.stringify(unChanged.value) !== JSON.stringify(changed.value)) {
                  let obj = {
                    formRecord: [
                      { application: { id: "", value: "" } },
                      { table: { id: "", value: thiss.state.tabName } },
                      { record: list[0].rcd[row].colr },
                    ],
                  };
                  rcd.push(JSON.stringify(obj));
                  dupRefList[0].rcd[row].colr[col]=changed;
                  break;
                }
              }
            }
            console.log("rcd forUpdate", rcd);
        
            // thiss.setState({
            //   btn_disable: true,
            //   loading: true,
            //   page_message: false,
            //   message: "",
            //   page_error: false,
            //   error: "",
            // });

            // let onSubmitResponse = false;
            // let isGreenSingal = true;
           // let error_String = "";
            // for (let k = 0; k < rcd.length; k++) {
            //   let singleRow = rcd[k];
          
            //  let res = await thiss.runForEachRecord(singleRow, btn);
            //  console.log("runScriptOnEachRecord", res);
        
            //   if (res.mandatory.length > 0) {
            //     error_String +=
            //       " Check mandatory fields not set: " + res.mandatory.toString() +" of record "+res?.rcdId;
            //     break;
            //   }
            //   if (res.unverified.length > 0) {
            //     error_String += " Fields unverified:" + res.unverified  +" of record "+res?.rcdId;
            //     break;
            //   }
        
            //   if (res.onSubmitResponse) {
            //     onSubmitResponse = true;
            //     break;
            //   }
            // }
        
            // if (error_String) {
            //   isGreenSingal = false;
            //   thiss.setState({
            //     loading: false,
            //     page_error: true,
            //     error: error_String,
            //   });
            // }
        
           // console.log("final ", isGreenSingal, onSubmitResponse);
        
            let token = localStorage.getItem("token");
     //       if (isGreenSingal && !onSubmitResponse) {
      if (rcd?.length!=0) {
              axios
                .post(thiss.state.loca + "/loom/update/multiplerecord", rcd, {
                  headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                  },
                })
                .then((resp) => {
                  const refrencercd = resp.data;
                  console.log("multipleUpdate: ",refrencercd);
               //   thiss.refresh();
           //    thiss.multipleSelectedRows.current=[];
               thiss.setState({
                // loading: false,
                // btn_disable: false, 
                refList:dupRefList

              });
                });
            } else {
              thiss.setState({
                loading: false,
                btn_disable: false, 
              });
            }
          };

 export function     handleMouseUpFn  ({thiss ,document})  {
            thiss.setState({
              isDragging: false,
              dragStartCell: null,
            });
                 // Re-enable text selection
            document.body.style.userSelect = "auto";
        
            // Reset cursor back to default
            document.body.style.cursor = "default";
          };